
import Card from "../../components/Card/Card";
import { useLocation } from "react-router-dom";
import {
    ModalWrapper, Button
 } from 'carbon-components-react';
const IFrameDocument = ({mediaId,documentName}) => {
    const user = localStorage.getItem("principal");
    const { state } = useLocation();

    // const urlServer = 'http://testefeiraside.ologa.com:8989/api/v1/media/file/';
    const urlServer = 'http://www.feiraside.ologa.com:9090/ide-feiras/api/v1/media/file/';

    // const urlServer = 'http://localhost:8080/ide-feiras/api/v1/media/file/';
    // const urlServer = 'http://192.168.88.2:8080/ide-feiras/api/v1/media/file/';


    const request = new Request(urlServer + mediaId, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${JSON.parse(user).token}`,
        },
        mode: "cors",
        cache: "default",
    });

  


    return (
        <>
        <Button
        
        iconDescription="Save"
        onClick={() => {
            fetch(request)
            .then((response) => response.blob())
            .then((blob) => {
                const file = window.URL.createObjectURL(blob);
                window.open(file);
            })
            .catch((err) => {
                console.log(err);
            });
        }}>
            {documentName}
        </Button>
        </>
    );
};

export default IFrameDocument;