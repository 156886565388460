import React, { useEffect, useState, useContext } from 'react';
import http from '../../http-common';

import {
  ModalWrapper, Form, Row,
  Column,
  TextInput,
  Loading,

} from 'carbon-components-react';

import { toast } from "react-toastify";
import { AuthContext } from '../../contexts/AuthContext';

const PresenceFairPage = (fair, sellerId) => {
  const [numberOfStall, setNumberOfStall] = useState({});
  const [showLoading, setShowLoading] = useState(false);

  const authContext = useContext(AuthContext);

  const handleNumberOfStall = (e) => {
    setNumberOfStall({
      value: e.nativeEvent.data
    });
    if (e.nativeEvent
      .data > 2) {
      toast.error("Numero Invalido de Bancas deve ser de 1 a 2");
    }
  };

  const submitApproval = async () => {
    try {
      setShowLoading(true);

      const response = await http.post('seller/fair/approval/' + fair.sellerId + '/' + fair.fair.id + "/" + 1);//numberOfStall.value);

      if (response.status === 200) {
        toast.success("Solicitação enviada com sucesso");
        setShowLoading(false);
        window.location.reload();
      }

    } catch (error) {
      toast.error(error.response?.data.message);
    }
  };
  return (
    <>
      <ModalWrapper
        buttonTriggerText="Solicitar Participação"
        modalHeading={`Participação na Feira ${fair.fair.description}`}
        modalLabel=""
        primaryButtonText="Solicitar"
        secondaryButtonText={null}
        handleSubmit={() => submitApproval()}
      >
        <p>Nota: O pedido de participação para a Feira: <strong>{fair.fair.description}</strong> será apenas de 1 banca, qualquer informacao adicional, entre em contacto com o numero ou email de suporte.</p>
        <strong>Solicite e garanta a sua participação</strong>
        {/* <strong>Este pedido irá garantir fazer vendas na feira</strong> */}
        {/* <p>Indique o numero de bancas que prentede para esta Feira de {fair.fair.description}</p>
        {" "} */}

        {/* <Row>
          <Column sm={8} md={8} lg={8}>

            <TextInput
              id="numberOfStalls"
              maxLength={1}
              labelText="Numero de Bancas"
              placeholder="O Numero de Bancas deve ser de 1 a 2"
              onChange={handleNumberOfStall}
            />
            <p>Numero de Bancas deve ser de 1 a 2</p>

            {
              showLoading == true ?
                <Loading
                  description="Active loading indicator" withOverlay={false}
                  small /> : ""
            }
          </Column>

        </Row> */}



      </ModalWrapper>
    </>
  );
};

export default PresenceFairPage;