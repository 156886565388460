import React, { useEffect, useState } from 'react';
import CustomTable from '../../components/custom-table/custom-table';
import { Button,Link, OverflowMenu, OverflowMenuItem, Pagination } from 'carbon-components-react';
import http from '../../http-common';


const ObjectTableList = ({title, endpoint, headers, dataObjectName, addButton, fairId}) => {

    const [tableRows, setTableRows] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPageSize, setCurrentPageSize] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);


    const tableActions = (objectId) => {
        return (<>{'...'}</>)
    }
    const rowLink = (objectId) => {
        return (<Link href={'#/fairs/' }>{objectId}</Link>)
    }
    const getData = async (page, pageSize) => {
        let startOffset = page == 0 ? 0 : page - 1;
        const response = await http.get('/' + endpoint + '&page=' + startOffset);
        const data = response.data;
        const rows = data[dataObjectName].map( d => {
            let obj = {};
            obj.id = d.id;
            headers.forEach(h => {
                if(h.key != 'actions') {
                    if(dataObjectName=='sellers' && h.key=='code' ) {
                        obj[h.key] = <Link href={'#/sellers/' + d.id + '/products' }>{d[h.key]}</Link>
                    } else {
                        obj[h.key] = d[h.key];
                    }
                
                }
            });
            obj.actions = tableActions(d.id);
            return obj;
        });

        setCurrentPageNumber(page)
        setCurrentPageSize(data[dataObjectName].length);
        setTotalItems(data.totalItems);
        setTableRows( rows);
    }

  

    useEffect(() => {
        const abortController = new AbortController();
        getData(0, 10);
        return () => { abortController.abort(); }
    }, []);



    return (
      
          <div style={{ marginTop: '3rem', backgroundColor: '#fff' }}>
            <CustomTable 
                tableTitle={title} 
                tableHeaders={headers} 
                tableRows={tableRows} 
                addButton={addButton} />
            <Pagination
                totalItems={totalItems}
                backwardText="Previous page"
                forwardText="Next page"
                pageSize={currentPageSize}
                pageSizes={[10, 20, 30, 40, 50]}
                itemsPerPageText="Items per page"
                onChange={({ page, pageSize }) => {
                    getData(page, pageSize);
                }}
                page={currentPageNumber}
            />  
            </div>
  
    )
}

export default ObjectTableList;