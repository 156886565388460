import React, { useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import logo from "../assets/images/mainlogo.png";
import { ToastContainer } from "react-toastify";
import { Column } from "carbon-components-react";

const PublicLayout = () => {
  useEffect(() => {
    document.body.classList.remove("admin-page");
    document.body.classList.add("public-page");
  }, []);
  return (
    <>
      <header>
        <div className="bx--grid">
          <Column sm={12} md={12} lg={10} xlg={10} className="header-wrap">
            <h1 className="logo">
              <a href="/">
                <img src={logo} alt="Feira Digital Fairs iDE" />
              </a>
            </h1>
          </Column>
        </div>
      </header>
      <main className="bx--grid main">
        <Column sm={12} md={12} lg={10} xlg={10} style={{ margin: "auto" }}>
          <Outlet />
          <ToastContainer
            theme="colored"
            position="top-right"
            autoClose={8000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Column>
      </main>
    </>
  );
};

export default PublicLayout;
