import Card from "../../components/Card/Card";
import ProductForm from "./ProductForm";



const CreateProductPage = () =>{

    return ( 
        <Card 
        title="Adicionar Produto"
        > 
        <ProductForm 
            mode={"CREATE"}
            url={"/sellerproducts"}
            sucessMessage={"Produto criado com sucesso"}
            onSuccessNavigateTo={"../inicio"}
            />
        </Card>
    );
};

export default CreateProductPage;