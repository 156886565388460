import React from "react";
import Card from "../../components/Card/Card";
import ProductRequest from "./ProductRequest";

const CreateNewProduct = () =>{ 

    return ( 
        <Card
        title="Requisitar Produto"
        > 
            <ProductRequest 
            mode={"CREATE"}
            url={"/sellerproducts"}
            sucessMessage={"Produto criado com sucesso"}
            onSuccessNavigateTo={"../inicio"}
            />
        </Card>
    );
};

export default CreateNewProduct;