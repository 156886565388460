import React, { useEffect } from "react";
import {
    Button,
    FileUploader,
    Form,
    TextInput,
    Row,
    Column,
    ButtonSet,
    Loading,
} from "carbon-components-react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import {
    providerSchema,
} from "../../services/SchemaService";
import http from "../../http-common";
import { useState } from "react";
import { SaveModel24 } from "@carbon/icons-react";
import ProvinceCombo from "../../components/Combos/ProvinceCombo";
import httpMedia from "../../http-common-media";

const ProviderForm = ({
    disabled,
    mode,
    url,
    successMessage,
    onSuccessNavigateTo,
    providerId,
}) => {
    const [opMode] = useState(mode);
    const [showProfiles] = useState(mode !== "CREATE");
    const [provider, setProvider] = useState({});
    const [showSuccess, setShowSuccess] = useState(false);
    const [data, setData] = useState(null);
    const [mader, setMader] = useState([]);
    const [nuit, setNuit] = useState([]);
    const [alvara, setAlvara] = useState([]);
    const [isMaderClicked, setMaderClicked] = useState(opMode === "UPDATE" ? true : false);
    const [isNuitClicked, setNuitClicked] = useState(opMode === "UPDATE" ? true : false);
    const [isAlvaraClicked, setAlvaraClicked] = useState(opMode === "UPDATE" ? true : false);
    const [documents, setDocuments] = useState([]);
    const [showLoading, setShowLoading] = useState(false);


    const getSchema = () => {
        switch (mode) {
            case "UPDATE":
                return providerSchema;
            case "CREATE":
                return providerSchema;
            default:
                return providerSchema;
        }
    };

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        control,
        formState: { errors },
    } = useForm({ resolver: yupResolver(getSchema()), mode: "onBlur" });

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        if (isMaderClicked === true && isNuitClicked === true && isAlvaraClicked === true) {
            setShowLoading(true);
            try {
                opMode === "UPDATE"
                    ? await http.post(url, data)
                    : await http.post(url + '?email=' + getValues('email'), data).then(async (response) => {

                if (response.status === 200) { 
              
                await httpMedia.post('/media/upload/alvara/' + response.data.id, alvara);
                await httpMedia.post('/media/upload/nuit/' + response.data.id, nuit);
                await httpMedia.post('/media/upload/mader/' + response.data.id, mader);
                setShowLoading(false);
                }

                });
                toast.success(successMessage);

                if (opMode === "SIGNUP") {
                    reset({});
                    setData(data);
                    setShowSuccess(true);
                } else {
                    navigate(onSuccessNavigateTo, { replace: true });
                }
            } catch (error) {
                toast.error(error.response?.data.message);
            }
        } else {
            toast.error('Documentos em anexo em Falta');

        }

    };

    const onFileChange = event => {
        const file = window.URL.createObjectURL(event.target.files[0]);
        const formData = new FormData();
        formData.append(
            "file",
            event.target.files[0],
            "Mader -" + " " + getValues('firstName') + "_" + event.target.files[0].name
        );
        setMader(formData);
        setMaderClicked(true);
        setDocuments([...documents, formData]);
    };

    const onAlvaraChange = event => {
        const file = window.URL.createObjectURL(event.target.files[0]);
        const formData = new FormData();
        formData.append(
            "file",
            event.target.files[0],
            "Alvara -" + " " + getValues('firstName') + "_" + event.target.files[0].name
        );
        setAlvara(formData);
        setAlvaraClicked(true);
        setDocuments([...documents, formData]);
    };

    const onNuitChange = event => {
        const file = window.URL.createObjectURL(event.target.files[0]);
        const formData = new FormData();
        formData.append(
            "file",
            event.target.files[0],
            "Nuit -" + " " + getValues('firstName') + "_" + event.target.files[0].name
        );
        setNuit(formData);
        setNuitClicked(true);
        setDocuments([...documents, formData]);
    };

    useEffect(() => {
        if (opMode === "UPDATE") {
            const fetchData = async () => {
                try {
                    const result = await http.get(`/sellers/${providerId}`);
                    const fields = [
                        "id",
                        "username",
                        "firstName",
                        "surName",
                        "documentCode",
                        "contact",
                        "community",
                    ];
                    fields.forEach((field) => setValue(field, provider[field]));
                    setProvider(result.data);
                } catch (error) {
                    toast.error(error.response?.data.message);
                }
            };

            fetchData();
        }
        setShowSuccess(false);
    }, [opMode]);

    useEffect(() => {
        reset(provider);
    }, [provider]);

    return (
        <>
            {showSuccess ? (
                <div className="emprega-green">
                    <h5>Obrigado por te registares</h5>
                    <p>
                        Aguarde aprovação para poderes aceder à tua conta
                    </p>

                </div>
            ) : (

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Column sm={6} md={6} lg={6} className="required">
                            <TextInput
                                id="firstName"
                                labelText="Nome Comercial"
                                maxLength={64}
                                {...register("firstName")}
                            />
                            <p className="field-error">{errors.firstName?.message}</p>
                        </Column>
                        <Column sm={6} md={6} lg={6} className="required">
                            <TextInput
                                id="surName"
                                labelText="Nome Legal"
                                maxLength={64}
                                {...register("surName")}
                            />
                            <p className="field-error">{errors.surName?.message}</p>
                        </Column>
                    </Row>

                    <Row>
                        <Column sm={12} md={6} lg={6} className="required">
                            <TextInput
                                id="documentCode"
                                labelText="Nuit"
                                {...register("documentCode")}
                                maxLength={9}
                            />
                            <p className="field-error">{errors.documentCode?.message}</p>
                        </Column>
                        <Column sm={12} md={6} lg={6} className="required">
                            <TextInput
                                id="contact"
                                labelText="Contacto"
                                {...register("contact")}
                                maxLength={9}
                            />
                            <p className="field-error">{errors.contact?.message}</p>
                        </Column>
                    </Row>

                    <Row>
                        <Column sm={6} md={6} lg={6} className="required">
                            <ProvinceCombo
                                id="provinceId"
                                name="provinceId"
                                label="Província"
                                disabled={disabled}
                                control={control}
                            />
                            <p className="field-error">{errors.provinceId?.message}</p>
                        </Column>

                        <Column sm={6} md={6} lg={6} className="required">
                            <TextInput
                                id="community"
                                labelText="Comunidade"
                                maxLength={64}
                                {...register("community")}
                            />
                            <p className="field-error">{errors.community?.message}</p>
                        </Column>
                    </Row>
                    <Row>
                        <Column sm={6} md={6} lg={6} className="required">
                            <TextInput
                                id="nameOfResponsible"
                                labelText="Nome do Responsavel"
                                maxLength={64}
                                {...register("nameOfResponsible")}
                            />
                        </Column>
                        <Column sm={6} md={6} lg={6} className="required">
                            <TextInput
                                id="email"
                                labelText="Email"
                                maxLength={64}
                                {...register("email")}
                            />
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <div className="cds--file__container">
                                <FileUploader
                                    accept={[
                                        '.jpg',
                                        '.pdf'
                                    ]}
                                    buttonKind="tertiary"
                                    buttonLabel="Alvará"
                                    filenameStatus="edit"
                                    iconDescription="Clear file"
                                    labelTitle="Carregar Alvará"
                                    labelDescription="O tamanho maximo é de 20MB"
                                    multiple
                                    name=""
                                    onChange={onAlvaraChange}
                                    role="button"
                                    size="md"
                                />
                            </div>

                        </Column>
                        <Column>
                            <div className="cds--file__container">
                                <FileUploader
                                    accept={[
                                        '.jpg',
                                        '.pdf'
                                    ]}
                                    buttonKind="tertiary"
                                    buttonLabel="NUIT"
                                    filenameStatus="edit"
                                    iconDescription="Clear file"
                                    labelTitle="Carregar NUIT"
                                    labelDescription="O tamanho maximo é de 20MB"
                                    multiple
                                    name=""
                                    onChange={onNuitChange}
                                    role="button"
                                    size="md"
                                />
                            </div>
                        </Column>
                        <Column>
                            <div className="cds--file__container">
                                <FileUploader
                                    accept={[
                                        '.jpg',
                                        '.pdf'
                                    ]}
                                    buttonKind="tertiary"
                                    buttonLabel="MADER"
                                    filenameStatus="edit"
                                    iconDescription="Clear file"
                                    labelTitle="Carregar MADER"
                                    labelDescription="O tamanho maximo é de 20MB"
                                    multiple
                                    name=""
                                    onChange={onFileChange}
                                    role="button"
                                    size="md"
                                />
                            </div>
                        </Column>
                    </Row>

                    <Row className="mt-2">
                        <Column sm={12} md={6} lg={6}>
                            <ButtonSet>
                                <Button
                                    type="submit"
                                    renderIcon={SaveModel24}
                                    iconDescription="Save"
                                >
                                    Salvar
                                    {
                                        showLoading == true ?
                                            <Loading
                                                description="Active loading indicator" withOverlay={false}
                                                small /> : ""
                                    }

                                </Button>
                                <Button
                                    onClick={() => navigate("/")}
                                >
                                    Voltar
                                </Button>
                            </ButtonSet>
                        </Column>
                    </Row>
                </Form>
            )}
        </>

    );
};

export default ProviderForm;
