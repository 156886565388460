import { Button } from "carbon-components-react";
import { Column } from "carbon-components-react";
import { Row } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../http-common";

const TrackGrievancePage = () => {
  const params = useParams();
  const [occurrenceNumber] = useState(params.occurrenceNumber);
  const [grievance, setGrievance] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await http.get(
          `grievances/search/occurrence/${occurrenceNumber}`
        );
        setGrievance(result.data);
      } catch (error) {
        toast.error(error.response?.data.message);
      }
    };

    fetchData();
  }, [occurrenceNumber]);

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Verificar Estado da Reclamação</h3>
      </div>
      <div className="card-body" style={{ color: "#000" }}>
        <Row>
          <Column sm={6} md={6} lg={6}>
            <div className="mb-1">
              <strong>Número de Occorrência: </strong>
              {grievance?.occurrenceNumber}
            </div>
            <div className="mb-1">
              <strong>Tipo: </strong>
              {grievance?.type?.description}
            </div>
            <div className="mb-1">
              <strong>Assunto: </strong>
              {grievance?.subject}
            </div>
            <div className="mb-1">
              <strong>Descrição: </strong>
              {grievance?.description}
            </div>
          </Column>
          <Column sm={6} md={6} lg={6}>
            <div className="mb-1">
              <strong>Estado da reclamação: </strong>
              {grievance?.currentStatus?.description}
            </div>
          </Column>
        </Row>
        <Button type="submit" className="mt-2" onClick={() => navigate("/")}>
          Sair
        </Button>
      </div>
    </div>
  );
};

export default TrackGrievancePage;
