import * as yup from "yup";

const REQUIRED_FIELD_MESSAGE = "Campo obrigatório";
const INVALID_PHONE_MESSAGE = "Número de telefone inválido";
const INVALID_NUIT_MESSAGE = "O NUIT deve ter 9 digitos";
const INVALID_PASSWORD_MESSAGE = "A senha deve conter letras e digitos apenas";
const NOT_A_NUMBER_MESSAGE = "Valor deve ser numérico";
const INVALID_MIN_NUMBER_MESSAGE = "Número não pode ser menor que zero";
const INVALID_SCORE_MESSAGE =
  "Pontuação Inválida. Insira um número entre 0 e 5";

const INVALID_NUMBEROFSTALLS_MESSAGE =
  "Numero Inválido. Insira um número entre 0 e 5";
const INVALID_THRESHOLD =
  "Preenche corretamente a quantidade";
const isRequiredStringThreshold = yup
  .string()
  .typeError(INVALID_THRESHOLD)
  .required(INVALID_THRESHOLD);
const isRequiredString = yup
  .string()
  .typeError(REQUIRED_FIELD_MESSAGE)
  .required(REQUIRED_FIELD_MESSAGE);
const isRequiredNumber = yup.number().required(REQUIRED_FIELD_MESSAGE);
const isNumber = yup
  .number()
  .transform((v, o) => (o === "" ? null : v))
  .typeError(NOT_A_NUMBER_MESSAGE)
  .nullable();
const isNullablePositiveInteger = yup
  .number()
  .transform((v, o) => (o === "" ? null : v))
  .typeError(NOT_A_NUMBER_MESSAGE)
  .integer("Número deve ser inteiro")
  .min(0, INVALID_MIN_NUMBER_MESSAGE)
  .nullable();
const isValidPhoneNumber = yup.string().matches(/^8(2|3|4|5|6|7)[0-9]{7}$/, {
  message: INVALID_PHONE_MESSAGE,
  excludeEmptyString: true,
});
const isValidNuit = yup
  .string()
  .matches(/^[0-9]{9}$/, {
    message: INVALID_NUIT_MESSAGE,
    excludeEmptyString: true,
  })
  .nullable();
const isValidEmail = yup.string().email("Email inválido").nullable();
const isValidDate = yup
  .string()
  .matches(
    /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
    "Data inválida. Use o formato dd/mm/aaaa"
  );

const isValidScore = yup
  .number()
  .transform((v, o) => (o === "" ? null : v))
  .typeError(INVALID_SCORE_MESSAGE)
  .integer(INVALID_SCORE_MESSAGE)
  .min(0, INVALID_SCORE_MESSAGE)
  .max(5, INVALID_SCORE_MESSAGE);

const roleRules = yup.object({
  role: yup.string().required(REQUIRED_FIELD_MESSAGE),
});

const passwordRules = yup.object({
  password: yup
    .string()
    .transform((x) => (x === "" ? undefined : x))
    .required(REQUIRED_FIELD_MESSAGE)
    .min(6, "A senha deve ter no mínimo 6 caracteres")
    .max(20, "A senha deve ter no máximo 20 caracteres")
    .matches(
      /^(?=.*\d)[^#&!~<>?=+*()-+=\/?@{}_$%]+$/,
      INVALID_PASSWORD_MESSAGE
    ),
  passwordConfirm: yup
    .string()
    .transform((x) => (x === "" ? undefined : x))
    .required(REQUIRED_FIELD_MESSAGE)
    .oneOf([yup.ref("password")], "As senhas devem ser iguais"),
});


const isValidNumberOfStalls = yup
  .number()
  .transform((v, o) => (o === "" ? null : v))
  .typeError(INVALID_NUMBEROFSTALLS_MESSAGE)
  .integer(INVALID_NUMBEROFSTALLS_MESSAGE)
  .min(0, INVALID_NUMBEROFSTALLS_MESSAGE)
  .max(5, INVALID_NUMBEROFSTALLS_MESSAGE);

const providerSchema = yup.object({
  firstName: isRequiredString,
  surName: isRequiredString,
  contact: isRequiredString.concat(isValidPhoneNumber),
  documentCode: isRequiredString.concat(isValidNuit),
  community: isRequiredString,
});

const userSchema = yup.object({
  firstname: isRequiredString,
  lastname: isRequiredString,
  username: isRequiredString,
  email: isRequiredString.concat(isValidEmail),
});

const basicApplicationSchema = yup.object({
  firstName: isRequiredString,
  lastName: isRequiredString,
  nuit: isValidNuit,
  email: isValidEmail,
  birthDate: isRequiredString.concat(isValidDate),
  gender: isRequiredString,
  identificationDocTypeCode: isRequiredString,
  otherIdentificationDocType: yup
    .string()
    .when("identificationDocTypeCode", {
      is: "0",
      then: yup.string().required("Especifique o tipo"),
    })
    .nullable(true),
  identificationDocNumber: isRequiredString,
  phoneNumber1: isRequiredString.concat(isValidPhoneNumber),
  phoneNumber2: isValidPhoneNumber,
  altContactPersonPhoneNumber: isValidPhoneNumber,
  numberOfExpectedWorkers: isNullablePositiveInteger,
  numberOfExpectedFemaleWorkers: isNullablePositiveInteger,
  numberOfExpectedMaleWorkers: isNullablePositiveInteger,
  numberOfExpectedWorkersAged18To35: isNullablePositiveInteger,
  numberOfExistingWorkers: isNullablePositiveInteger,
  numberOfExistingMaleWorkers: isNullablePositiveInteger,
  numberOfExistingFemaleWorkers: isNullablePositiveInteger,
  numberOfExistingMaleWorkersAged18To35: isNullablePositiveInteger,
  numberOfExistingFemaleWorkersAged18To35: isNullablePositiveInteger,
});

const submitApplicationSchema = yup.object({
  altContactPersonName: isRequiredString,
  altContactPersonPhoneNumber: isRequiredString,
  altContactPersonRelationship: isRequiredString,
  provinceId: isRequiredString,
  districtId: isRequiredString,
  neighborhood: isRequiredString,
  street: isRequiredString,
  addressTypeCode: isRequiredString,
  academicLevelCode: isRequiredString,
  competitorData: isRequiredString,
  hasBusinessCurrently: isRequiredString,
  businessIdea: isRequiredString,
  ideaProductOrService: isRequiredString,
  targetCustomerProfile: isRequiredString,
  workers: isRequiredString,
  numberOfExpectedWorkers: isRequiredNumber.concat(isNumber),
  numberOfExpectedFemaleWorkers: isRequiredNumber.concat(isNumber),
  numberOfExpectedMaleWorkers: isRequiredNumber.concat(isNumber),
  numberOfExpectedWorkersAged18To35: isRequiredNumber.concat(isNumber),
  howWillEnsureEqualEmploymentOpportunity: isRequiredString,
});

const submitBusinessProfileSchema = yup.object({
  businessProductOrService: isRequiredString,
  numberOfExistingWorkers: isRequiredNumber.concat(isNumber),
  numberOfExistingMaleWorkers: isRequiredNumber.concat(isNumber),
  numberOfExistingFemaleWorkers: isRequiredNumber.concat(isNumber),
  numberOfExistingMaleWorkersAged18To35: isRequiredNumber.concat(isNumber),
  numberOfExistingFemaleWorkersAged18To35: isRequiredNumber.concat(isNumber),
  workFacilitiesProvinceId: isRequiredString,
  workFacilitiesDistrictId: isRequiredString,
  workFacilitiesLocality: isRequiredString,
  workFacilitiesNeighborhood: isRequiredString,
  workFacilitiesStreet: isRequiredString,
  ownWorkFacilities: isRequiredString,

  // isBusinessManufacturerOrProducer: yup.boolean(),
  // isBusinessServiceProvider: yup.boolean(),
  // isBusinessRetailer: yup.boolean(),
  // isBusinessWholesaler: yup.boolean(),
});
// .test("myCustomTest", null, (obj) => {
//   if (
//     obj.isBusinessManufacturerOrProducer ||
//     obj.isBusinessServiceProvider ||
//     obj.isBusinessRetailer ||
//     obj.isBusinessWholesaler
//   ) {
//     return true; // everything is fine
//   }

//   return new yup.ValidationError(
//     "Selecione pelo menos uma das opções",
//     null,
//     "businessType"
//   );
// });

const grievanceSchema = yup.object({
  type: isRequiredString,
  subject: isRequiredString,
  description: isRequiredString,
});

const applicationScoreSchema = yup.object({
  q001: isValidScore,
  q002: isValidScore,
  q003: isValidScore,
  q004: isValidScore,
  q005: isValidScore,
  q006: isValidScore,
  q007: isValidScore,
  q008: isValidScore,
  q009: isValidScore,
});

const sellerProductSchema = yup.object({ quantity: isRequiredNumber, price: isRequiredNumber, category: isRequiredString, measurementUnit: isRequiredString, productId: isRequiredString, threshold: isRequiredStringThreshold, providerName: isRequiredString, lotNumber: isRequiredString,seedFile:isRequiredStringThreshold,receipt:isRequiredStringThreshold });

const sellerPresenceInFairSchema = yup.object({ numberOfStalls: isValidNumberOfStalls });

export {
  userSchema,
  roleRules,
  passwordRules,
  basicApplicationSchema,
  submitBusinessProfileSchema,
  submitApplicationSchema,
  grievanceSchema,
  applicationScoreSchema,
  sellerProductSchema,
  providerSchema,
  sellerPresenceInFairSchema,
};
