import {
    Button,
    Form,
    TextInput,
    Row,
    Column,
    ButtonSet,
    Dropdown,
    DataTable,
    OverflowMenu,
    OverflowMenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableToolbar,
    TableToolbarContent,
} from "carbon-components-react";
import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../http-common";
import { SaveModel24, Search32 } from "@carbon/icons-react";
import { AuthContext } from "../../contexts/AuthContext";
import { yupResolver } from "@hookform/resolvers/yup";
import Card from "../../components/Card/Card";
import TablePagination from "../../components/Pagination/Pagination";

const tableHeaders = [
    {
        header: "NUIT",
        key: "documentCode",
    },
    {
        header: "Fornecedor",
        key: "firstName",
    },
    {
        header: "Valor Pago",
        key: "amountPaid",
    },
    {
        header: "Total Na Feira",
        key: "totalAmountOnFair",
    },
];


const PaymentPage = ({ mode, url, sucessMessage, onSucessMessage }) => {
    const [formMode] = useState(mode);
    const [payment, setPayment] = useState({
    });
    const [provinces, setProvinces] = useState([]);
    const [fairs, setFairs] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [sellers, setSellers] = useState([]);
    const [count, setCount] = useState(0);
    const [size, setSize] = useState(20);
    const [parameters, setParameters] = useState();
    const [districtData, setDistrictData] = useState([]);
    const [newSellers, setNewSellers] = useState([]);
    const [selectedSeller, setSelectedSeller] = useState();
    const [amoutPaid, setAmoutPaid] = useState(0);
    const [rowsPayments, setRowsPayments] = useState([]);


    const navigate = useNavigate();

    const authContext =
        useContext(AuthContext);
    const onSearch = async (data) => {
        try {
            const response = await http.get(
                `/payments/${data.fairId}`
            );
            getSellerData(response);
            setParameters(data);

        } catch (error) { }
    };

    const onPageChange = async ({ page, pageSize }) => {
        try {
            var pag = page - 1;
            const response = await http.get(
                `/payments/${parameters.fairId}?page=${pag}&size=${pageSize}`
            );
            getSellerData(response);

        } catch (error) { }
    };


    const getSellerData = (response) => {
        const data = response.data.content.map((payment) => ({
            id: payment.id,
            documentCode: payment.seller.documentCode,
            firstName: payment.seller.firstName,
            amountPaid: payment.amountPaid.toLocaleString(),
            totalAmountOnFair: payment.totalAmountOnFair.toLocaleString(),
        }));
        setSellers(data);
        setCount(response.data.totalElements);
    };


    function handleChange(e) {
        payment.amountPaid = e.target.value;
        setAmoutPaid(e.target.value
        );
        const { name, value } = e.target;
        const seller = newSellers.find(s => { return s.seller.id === name })
        if (seller === undefined) {
            console.log("new", name);
            newSellers.push({ seller: { id: name }, amountPaid: value })
            setNewSellers(newSellers);

        } else {
            console.log("update", name);

            const newList = newSellers.map((s) => {
                if (s.seller.id === name) {
                    return { ...s, amountPaid: value }
                }

                return s;
            })
            if (newList.length > 0) {
                setNewSellers(newList);
            }

        }

        // setSellers(newList);
        // setNewSellers(newList);
        // console.log("newList",newList);
        console.log("nEWSELL", newSellers);


    }

    const onHandleSubmit = async () => {
        console.log("ListPayment", newSellers);
        try {

            const payments = newSellers.map((payment) => ({ seller: { id: payment.seller.id }, amountPaid: payment.amountPaid, fair: { id: getValues("fairId") }, user: { username: authContext.user.username } }));
            const response = await http.post(
                '/create/sellers/payments', payments
            );

            console.log("Sellers", sellers);


        } catch (error) { }
    };

    const fetchProvinces = async () => {
        try {
            const response = await http.get('/provinces');
            let items = response.data.map(e => {
                return { id: e.id, text: e.description, children: e.children };
            })
            return { success: true, data: items };
        } catch (error) {
            return { success: false };
        }
    };

    const fetchFairs = async () => {
        try {
            const response = await http.get('/fairs');
            let items = response.data.map(e => {
                return { id: e.id, text: e.description, };
            })
            return { success: true, data: items };
        } catch (error) {
            return { success: false };
        }
    };

    useEffect(() => {
        (async () => {
            let response = await fetchProvinces();
            let getFairs = await fetchFairs();
            if (response.success) {

                setProvinces(response.data);

            }
            if (getFairs.success) {

                setFairs(getFairs.data);

            }
        })()
    }, []);


    const { register, handleSubmit, reset, setValue, getValues, watch, formState: { errors }, } = useForm({ mode: "onSubmit" });
    const params = useParams();
    function onChangeSeller(e) {
        payment.provinceId = e.selectedItem.id;
        setSelectedSeller(e.selectedItem.id);
    };
    function onChangeAmoutPaid(e) {
        payment.amountPaid = e.target.value;
        setAmoutPaid(e.target.value
        );
        console.log("change", e);
    };
    function onProvinceChange(e) {
        payment.provinceId = e.selectedItem.id;
        setDistrictData(e.selectedItem.children);
        let response = e.selectedItem.children.map(c => {
            return { id: c.id, text: c.description };
        });
        setValue("provinceId", e.selectedItem.id);

        setDistricts(response);
    };

    function onDistrictChange(e) {
        payment.districtId = e.selectedItem.id;
        setValue("districtId", e.selectedItem.id);
    };

    function onFairChange(e) {
        payment.fairId = e.selectedItem.id;
        setValue("fairId", e.selectedItem.id);
    };



    return (

        <Card title="Pagamentos">
            <Form
                className="data-table-search-form"
                onSubmit={handleSubmit(onSearch)}
            >
                <Row>
                    <Column sm={3} md={4} lg={4}>
                        <Dropdown
                            id="fairId"
                            titleText="Feira"
                            label="Selecione"
                            items={fairs}
                            onChange={onFairChange}
                            name="fairId"
                            itemToString={item => (item ? item.text : '')}
                        />

                        <p className="field-error">{errors.fairId?.message}</p>
                    </Column>

                </Row>

                <Column>
                    <ButtonSet>
                        <Button
                            type="submit"
                            renderIcon={Search32}
                            iconDescription="Search"
                        >
                            Pesquisar
                        </Button>
                    </ButtonSet>
                </Column>

            </Form>

            <DataTable rows={sellers} headers={tableHeaders} isSortable useZebraStyles>
                {({ rows, headers, getHeaderProps, getTableProps }) => (
                    <TableContainer>
                        <TableToolbar aria-label="data table toolbar">
                            <TableToolbarContent>



                            </TableToolbarContent>
                        </TableToolbar>

                        <Table {...getTableProps()}>
                            <TableHead>

                                <TableRow>

                                    {headers.map((header) => (
                                        <TableHeader {...getHeaderProps({ header })}>
                                            {header.header}
                                        </TableHeader>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow key={row.id}>
                                        {row.cells.map((cell) => (

                                            <TableCell key={cell.id}>{cell.value}</TableCell>


                                        )

                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </DataTable>
            <TablePagination
                currentPageSize={size}
                onPageChange={onPageChange}
                totalItems={count}
            />

        </Card>
    );
};

export default PaymentPage;