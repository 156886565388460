// This should come from the database

const menu = (role) => {
  const menuItems = [
    {
      id: 1,
      label: "Minha conta",
      icon: "UserMultiple16",
      roles: ['ROLE_SELLER'],
      items: [

        {
          id: 1.1,
          label: "Registos",
          link: "/admin/detalhes-do-fornecedor",
          roles: ['ROLE_SELLER'],
        },
      
      ],
    },
    {
      id: 2,
      label: "Produtos do Vendedor",
      icon: "UserMultiple16",
      roles: ['ROLE_SELLER'],
      items: [
        {
          id: 2.1,
          label: "Registar",
          link: "/admin/create-product",
          roles: ['ROLE_ADMIN','ROLE_SELLER'],
        },
        {
          id: 2.2,
          label: "Consultar",
          link: "/admin/seller-product",
          roles: ['ROLE_ADMIN','ROLE_SELLER'],
        },
        {
          id: 2.3,
          label: "Requisitar Producto",
          link: "/admin/product-request",
          roles: ['ROLE_SELLER'],
        },
      ],
    },
    {
      id: 3,
      label: "Produtos",
      icon: "UserMultiple16",
      roles: ['ROLE_ADMIN',],
      items: [
        {
          id: 3.1,
          label: "Consultar",
          link: "/admin/product",
          roles: ['ROLE_ADMIN',],
        },
        
      ],
    },
    
    {
      id: 4,
      label: "Feiras",
      icon: "UserMultiple16",
      roles: ['ROLE_ADMIN','ROLE_APPLICANT'],
      items: [
        {
          id: 4.1,
          label: "Consultar",
          link: "/admin/fairs",
          roles: ['ROLE_ADMIN','ROLE_APPLICANT'],
        },
      ],
    },
    {
      id: 5,
      label: "Utilizadores",
      icon: "UserMultiple16",
      roles: ['ROLE_ADMIN'],
      items: [
        {
          id: 5.1,
          label: "Registar",
          link: "/admin/registar-utilizador",
          roles: ['ROLE_ADMIN'],
        },
        {
          id: 5.2,
          label: "Consultar",
          link: "/admin/utilizadores",
          roles: ['ROLE_ADMIN'],
        },
 
      ],
    },
    {
      id: 7,
      label: "Fornecedores",
      icon: "UserMultiple16",
      roles: ['ROLE_ADMIN',],
      items: [
        {
          id: 7.1,
          label: "Registar",
          link: "registar-fornecedor",
          roles: ['1'],
        },
        {
          id: 7.2,
          label: "Consultar",
          link: "fornecedores",
          roles: ['1'],
        },
      ],
    },
    
    {
      id: 8,
      label: "Feiras",
      icon: "UserMultiple16",
      roles: ['ROLE_SELLER'],
      items: [
        {
          id: 4.1,
          label: "Participacao",
          link: "/admin/fairs",
          roles: ['ROLE_SELLER'],
        },
      ],
    },
    {
      id: 8,
      label: "Aprovações",
      icon: "UserMultiple16",
      roles: ['ROLE_ADMIN','ROLE_APPLICANT'],
      items: [
        {
          id: 4.1,
          label: "Pendentes",
          link: "pending-approvals",
          roles: ['ROLE_ADMIN','ROLE_APPLICANT'],
        },
        {
          id: 4.1,
          label: "Aceitas",
          link: "accepted-approvals",
          roles: ['ROLE_ADMIN','ROLE_APPLICANT'],
        },
        {
          id: 4.1,
          label: "Rejeitadas",
          link: "rejected-approvals",
          roles: ['ROLE_ADMIN','ROLE_APPLICANT'],
        },
      ],
    },
    
    {
      id: 9,
      label: "Pagamentos",
      icon: "UserMultiple16",
      roles: ["ROLE_ACCOUNTANT", "ROLE_MA"],
      items: [
        {
          id: 9.1,
          label: "Registrar",
          link: "payments",
          roles: ["ROLE_ACCOUNTANT"],
        },
        {
          id: 9.2,
          label: "Consultar",
          link: "sellers-payments",
          roles: ["ROLE_ACCOUNTANT","ROLE_MA"],
        },
      
      ],
    },
  ];

  const userMenu = menuItems.filter((m) => m.roles.includes(role));
  userMenu.forEach((menu) => {
    menu.items = menu.items.filter((mi) => mi.roles.includes(role));
  });
  return userMenu;
};

export default menu;
