import {
    Button,
    Form,
    TextInput,
    Row,
    Column,
    ButtonSet,
    Dropdown,
    DatePicker,
    DatePickerInput,
    ModalWrapper,
    Loading,
} from "carbon-components-react";
import React, { useEffect, useState, useContext } from "react";
import Card from "../../components/Card/Card";
import { toast } from "react-toastify";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import http from "../../http-common";
import IFrameDocument from "../Home/Documents";
import { AuthContext } from "../../contexts/AuthContext";
import { async } from "q";

const ApprovalForm = () => {
    const [mediaSeedList, setMediaSeedList] = useState([]);
    const [mediaReceiptList, setMediaReceiptList] = useState({});

    const [showBtnSeed, setShowBtnSeed] = useState(true);
    const [showBtnReceipt, setShowBtnReceipt] = useState(true);

    const [mediaStatus, setMediaStatus] = useState();
    const [mediaReceiptStatus, setMediaReceiptStatus] = useState();

    const [mediaList, setMediaList] = useState([]);
    const [presence, setPresence] = useState({});
    const [showLoading, setShowLoading] = useState(false);
    const [sellerProduct, setSellerProduct] = useState({});

    const navigate = useNavigate();

    const { state } = useLocation();
    const approvalStatus = (status) => {
        switch (status) {
            case 0:
                return 'Aceite';
            case 1:
                return 'Rejeitado';
            case 2:
                return 'Pedente de Aprovação';
            case 3:
                return 'Por Submeter'
            default:
                return '';
        }
    };

    const retrieveMediaSellerProduct = async (sellerId, productId) => {
        setShowLoading(true);

        const response = await http.get('/media/detailseed/' + sellerId + "/" + productId)
            .then((response) => {
                setMediaStatus(response.status);
                setMediaSeedList(response.data);


            })
            .catch((error) => {
                console.log(error);
            });

        const responseReceipt = await http.get('/media/detailreceipt/' + sellerId + "/" + productId)
            .then((response) => {
                setMediaReceiptStatus(response.status);
                setMediaReceiptList(response.data);
                setShowBtnReceipt(false);
                setShowLoading(false);

            })
            .catch((error) => {
                console.log(error);
            });


    };

    const retrieveMediaSellerProducts = async (sellerId, sellerProdId) => {
        setShowLoading(true);

        const response = await http.get('/media/certificates/' + sellerId + "/" + sellerProdId)
            .then((response) => {
                setMediaStatus(response.status);
                console.log(response.status);
                console.log("fjh", response.data);
                // const resp = response.data;
                // resp.map((prod) => {
                //     mediaSeedList.push(prod);
                //     console.log(prod);

                // });
                setMediaSeedList(response.data);
                setShowBtnReceipt(false);
                setShowLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });

        // const responseReceipt = await http.get('/media/purchasereceipt/' + sellerId + "/" + sellerProdId)
        //     .then((response) => {
        //         setMediaReceiptStatus(response.status);
        //         setMediaReceiptList(response.data);
        //         setShowBtnReceipt(false);
        //         setShowLoading(false);

        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });


    };
    const retrieveMediaSellerRegistration = async (sellerId) => {
        setShowLoading(true);

        const response = await http.get('/media/detailregistration/' + sellerId)
            .then((response) => {
                setMediaStatus(response.status);
                setMediaList(response.data);
                setShowBtnSeed(false);
                setShowLoading(false);

            })
            .catch((error) => {
                console.log(error);
            });

    };
    const retrieveSellerProduct = async (sellerId, productID) => {
        const response = await http.get('/sellerproduct/' + sellerId + "/" + productID)
            .then((response) => {
                setSellerProduct(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
    };

    const retrieveSellerProducts = async (sellerId, productID) => {
        // console.log("SELLER", sellerId);

        // console.log("PROD", productID);
        const response = await http.get('/sellerproducts/' + sellerId + "/" + productID);

        // .then((response) => {
        const sellerProds = response.data;
        sellerProds.map(async (prod) => {
            await retrieveMediaSellerProducts(sellerId, prod.id);
        })
        setSellerProduct(response.data);
        // })
        // .catch((error) => {
        //     console.log(error);
        // })
    };
    const retrievePresence = async (approvalId) => {
        const response = await http.get("/approval/presence/" + approvalId)
            .then((response) => {
                setPresence(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const fetchRelatedData = async () => {
        try {
            switch (state.approvalData.typeId?.description) {

                case "Certificado":
                    console.log("SELLER", state.approvalData.seller?.id);

                    console.log("PROD", state.approvalData.product?.id);
                    retrieveMediaSellerProducts(state.approvalData.seller?.id, state.approvalData.product?.id)
                    // await retrieveSellerProducts(state.approvalData.seller?.id, state.approvalData.product?.id)
                    // await retrieveSellerProduct(state.approvalData.seller?.id, state.approvalData.product?.id);
                    // await retrieveMediaSellerProduct(state.approvalData.seller?.id, state.approvalData.product?.id);
                    break;
                case "Registro do Vendendor":
                    await retrieveMediaSellerRegistration(state.approvalData.seller?.id);
                    break;
                case "Participacao na Feira":
                    await retrievePresence(state.approvalData.id);
                    break;
            }

        } catch (error) {
            toast.error(error);
        }
    };
    useEffect(() => {
        fetchRelatedData();

    }, []);


    const authContext =
        useContext(AuthContext);
    const acceptApproval = async () => {
        try {
            const response = await http.post("/approval/approve/" + state.approvalData.id + "/" + authContext.user.username);
            if (response.status === 200) {
                toast.success("Aprovacao Com Sucesso");
            } else {
                toast.success("Ocorreu algum Erro!");
            }

        } catch (error) {
            toast.error(error.response?.data.message);
        }

    };

    const rejectApproval = async () => {
        try {
            const response = await http.post("/approval/reject/" + state.approvalData.id + "/" + authContext.user.username);
            if (response.status === 200) {
                toast.success("Rejeitado Com Sucesso");
            } else {
                toast.success("Ocorreu algum  Erro!");
            }

        } catch (error) {
            toast.error(error.response?.data.message);
        }

    };
    return (
        <Card
            title={"Detalhes da Approvação"} >
            <Form>
                <Row>
                    <Column>
                        {state.approvalData.status != null ? <TextInput
                            id={"status"}
                            labelText={"Estado"}
                            disabled
                            value={approvalStatus(state.approvalData.status)}
                        /> : ""
                        }
                    </Column>
                    <Column>
                        {state.approvalData.typeId?.description != null ? <TextInput
                            id={"type"}
                            labelText={"Tipo"}
                            disabled
                            value={state.approvalData.typeId?.description}
                        /> : ""
                        }
                    </Column>
                </Row>
                <Row>
                    <Column>
                        {state.approvalData.seller?.firstName != null ? <TextInput
                            id={"sellerName"}
                            labelText={"Nome do Vendedor"}
                            disabled
                            value={state.approvalData.seller?.firstName}
                        />
                            : ""
                        }
                    </Column>
                    <Column>
                        {state.approvalData.seller?.documentCode != null ? <TextInput
                            id={"documentCode"}
                            labelText={"Nuit do Vendedor"}
                            disabled
                            value={state.approvalData.seller?.documentCode}
                        />
                            : ""
                        }
                    </Column><Column>
                        {state.approvalData.seller?.contact != null ? <TextInput
                            id={"contact"}
                            labelText={"Contacto do Vendedor"}
                            disabled
                            value={state.approvalData.seller?.contact}
                        />
                            : ""
                        }
                    </Column>

                    <Column>
                        {state.approvalData.seller?.nameOfResponsible != null ? <TextInput
                            id={"nameOfResponsable"}
                            labelText={"Nome do Responsavel"}
                            disabled
                            value={state.approvalData.seller?.nameOfResponsible}
                        /> : ""
                        }
                    </Column>
                </Row>
                <Row>

                    <Column>
                        {state.approvalData.product?.description != null ? <TextInput
                            id={"productDescription"}
                            labelText={"Produto"}
                            disabled
                            value={state.approvalData.product?.description}
                        /> : ""
                        }
                    </Column>
                    <Column>
                        {state.approvalData.product?.category.description != null ? <TextInput
                            id={"category"}
                            labelText={"Categoria"}
                            disabled
                            value={state.approvalData.product?.category.description}
                        /> : ""
                        }
                    </Column>
                </Row>
                {/* <Row>
                    <Column>
                        {state.approvalData.product?.category.description == "Sementes" && mediaStatus === 200 ? <TextInput
                            id={"lotNumber"}
                            labelText={"Numero de Lote"}
                            disabled
                            value={sellerProduct?.lotNumber}
                        /> : ""
                        }
                    </Column>
                    <Column>
                        {state.approvalData.product?.category.description == "Sementes" && mediaStatus === 200 ? <TextInput
                            id={"providerName"}
                            labelText={"Nome do Fornecedor"}
                            disabled
                            value={sellerProduct?.providerName}
                        /> : ""
                        }
                    </Column>
                </Row> */}
                <Row>

                    <Column>
                        {
                            showLoading == true ?
                                <Loading
                                    description="Active loading indicator" withOverlay={false}
                                    small /> : ""
                        }
                        {state.approvalData.product?.category.description == "Sementes" && mediaStatus === 200 ?
                            <div className="cds--file__container">
                                {/* <br /> */}
                                {
                                    mediaSeedList.map(document => (
                                        <div className="cds--file__container">
                                            <br />
                                            <IFrameDocument
                                                mediaId={document.id}
                                                documentName={"Ver Doc: " + document?.name?.toString().split("-")[0] + "-Data: " + document?.createdAt?.toString().split(":")[0] + "h"}
                                            />
                                            <br />
                                            <Row>
                                                <Column>
                                                    {state.approvalData.product?.category.description == "Sementes" && mediaStatus === 200 ? <TextInput
                                                        id={"lotNumber"}
                                                        labelText={"Numero de Lote"}
                                                        disabled
                                                        value={document.lotNumber}
                                                    /> : ""
                                                    }
                                                </Column>
                                                <Column>
                                                    {state.approvalData.product?.category.description == "Sementes" && mediaStatus === 200 ? <TextInput
                                                        id={"providerName"}
                                                        labelText={"Nome do Fornecedor"}
                                                        disabled
                                                        value={document.providerName}
                                                    /> : ""
                                                    }
                                                </Column>
                                            </Row>
                                        </div>
                                    ))
                                }
                                {/* <IFrameDocument
                                    mediaId={media.id}
                                    documentName={"Ver Certificado "}
                                /> */}
                                {/* {
                                    mediaReceiptList.map(document => (
                                        <div className="cds--file__container">
                                            <br /> <br />
                                            <IFrameDocument
                                                mediaId={document.id}
                                                documentName={"Ver Recibo de Compra " + document.createdAt.toString().split(":")[0] + "h"}
                                            />

                                        </div>
                                    ))
                                } */}
                                {/* <br /> <br />
                                <IFrameDocument
                                    mediaId={mediaReceipt.id}
                                    documentName={"Ver Recibo de Compra "}
                                /> */}
                            </div>


                            : ""}
                    </Column>
                    <Column>
                        {
                            showLoading == true ?
                                <Loading
                                    description="Active loading indicator" withOverlay={false}
                                    small /> : ""
                        }
                        {
                            mediaList.map(document => (
                                <div className="cds--file__container">
                                    <br />
                                    <IFrameDocument
                                        mediaId={document.id}
                                        documentName={"Ver Documento " + document?.name.toString().split("-")[0]}
                                    />
                                </div>
                            ))
                        }
                    </Column>
                </Row>
                <Row>
                    <Column>
                        {state.approvalData.fair?.description != null ? <TextInput
                            id={"fairName"}
                            labelText={"Feira"}
                            disabled
                            value={state.approvalData.fair?.description}
                        /> : ""
                        }
                    </Column>
                    <Column>
                        {presence?.numberOfStalls != null ? <TextInput
                            id={"numberOfStalls"}
                            labelText={"Numero de Bancas"}
                            disabled
                            value={presence?.numberOfStalls}
                        /> : ""
                        }
                    </Column>
                </Row>
                <br /><br />

                <ButtonSet>
                    <Button
                        onClick={acceptApproval}
                    >
                        Aprovar Pedido
                    </Button>


                    <Button
                        onClick={rejectApproval}
                    >
                        Rejeitar Pedido
                    </Button>
                </ButtonSet>
            </Form>
        </Card>
    );
};
export default ApprovalForm;