import React, { useState, useEffect } from "react";
import { useContext } from "react";
import CustomTable from "../../components/custom-table/custom-table";
import { AuthContext } from "../../contexts/AuthContext";
import http from "../../http-common";

const tableHeaders = [
    { key: 'description', header: 'Descrição' },
    { key: 'code', header: 'Código' },
    { key: 'balance', header: 'Valor' },
    { key: 'province', header: 'Província' },
    { key: 'district', header: 'Distrito' },
    { key: 'actions', header: 'Acções' },
];

const ProviderFairsPage = () => {


    const [fairs, setFairs] = useState([]);
    
    const authContext =
        useContext(AuthContext);

    const fetchProviderFairs = async (id) => {
        const response = await http.get('/sellers/' + id + '/fairs');

        const data = response.data.map(fair => {
            return {
                id: fair.id,
                code: fair.code,
                description: fair.description,
                balance: fair.balance,
                province: fair.province.description,
                district: fair.district.description,
            }

        })
        setFairs(data);

        return response.data;
    };

    useEffect(() => {
        fetchProviderFairs(authContext.sellerID);
    }, []);

    return (
        <>
            <div style={{ marginTop: '3rem', backgroundColor: '#fff' }}>
                <CustomTable
                    tableTitle="Feiras"
                    tableHeaders={tableHeaders}
                    tableRows={fairs}
                />

            </div>
        </>
    )
}

export default ProviderFairsPage;