import React, { useEffect, useState } from "react";
import Card from "../../components/Card/Card";
import { toast } from "react-toastify";
import http from "../../http-common";
import { Row } from "carbon-components-react";
import { Column } from "carbon-components-react";
import { TextInput } from "carbon-components-react";
import { useForm } from "react-hook-form";
import { Form } from "carbon-components-react";
import { Button } from "carbon-components-react";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { yupResolver } from "@hookform/resolvers/yup";
import { applicationScoreSchema } from "../../services/SchemaService";

const ScoreApplicationPage = () => {
  const params = useParams();
  const [uuid] = useState(params.uuid);
  const { user } = useAuth();
  const [criteria, setCriteria] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(applicationScoreSchema),
    mode: "onBlur",
  });

  const navigate = useNavigate();

  const getCriteria = async () => {
    try {
      const response = await http.get("/applications/evaluation-domains");
      setCriteria(response.data);
    } catch (error) {
      toast.error("Erro ao carregar mapa de pontuação");
    }
  };

  useEffect(() => {
    getCriteria();
  }, []);

  const onSubmit = async (data) => {
    try {
      data.uuid = uuid;
      data.username = user.username;
      console.log(data);
      const response = await http.post("/applications/score-application", data);
      toast.success("Pontuação submetida com sucesso");
      navigate("../avaliacao-de-candidaturas/");
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  };

  return (
    <Card title="Pontuar Candidatura">
      <Form onSubmit={handleSubmit(onSubmit)}>
        {criteria.map((d) => (
          <div className="mt-2" key={d.domainName.code}>
            <h4 style={{ borderBottom: "1px solid #dedede" }}>
              {d.domainName.description}
            </h4>
            {d.criteria.map((c) => (
              <Row key={c.code}>
                <Column sm={12} md={6} lg={6}>
                  <p className="mt-1">{c.question}</p>
                </Column>
                <Column sm={12} md={2} lg={2}>
                  <TextInput
                    id={c.code}
                    labelText=""
                    placeholder="0-5 pts"
                    {...register(c.code)}
                  />
                  <p className="field-error">{errors[c.code]?.message}</p>
                </Column>
                <Column sm={12} md={4} lg={4}>
                  <TextInput
                    id={c.code}
                    labelText=""
                    placeholder="Comentários"
                    maxLength="255"
                    {...register(c.code + "Remarks")}
                  />
                </Column>
              </Row>
            ))}
          </div>
        ))}

        <Button type="submit" className="mt-2">
          Salvar
        </Button>
      </Form>
    </Card>
  );
};

export default ScoreApplicationPage;
