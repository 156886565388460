
import React from 'react';
import DataTable, {
    Table,
    TableCell,
    TableContainer,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
    TableToolbarContent,
} from 'carbon-components-react/es/components/DataTable';
import './custom-table.scss';
import { TableToolbar } from 'carbon-components-react';

const CustomTable = ({ tableTitle, tableHeaders, tableRows, addButton, isLoading }) => {


    const content =

        <TableContainer title={tableTitle}>
            <TableToolbar>
                <TableToolbarContent>
                    {addButton}
                </TableToolbarContent>

            </TableToolbar>
            <DataTable rows={tableRows} headers={tableHeaders} isSortable={true} size="normal">
                {({ rows, headers, getHeaderProps, getTableProps }) => (
                    <Table {...getTableProps()}>
                        <TableHead>
                            <TableRow>
                                {headers.map(header => (
                                    <TableHeader
                                        className={`productiveHeading01 text02`}
                                        {...getHeaderProps({
                                            header,
                                            isSortable: header.isSortable,
                                        })}>
                                        {header.header?.content ?? header.header}
                                    </TableHeader>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map(row => (
                                <TableRow key={row.id}>
                                    {row.cells.map(cell => (
                                        <TableCell key={cell.id}>{cell.value?.content ?? cell.value}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </DataTable>
        </TableContainer>



    return (
        <> {content} </>
    );
}

export default CustomTable;