import {
    Select, Button,
    Form,
    TextInput,
    Row,
    Column,
    SelectItem,
    ButtonSet,
    Dropdown,
    FileUploader,
    Loading,
    ComboBox,
} from "carbon-components-react";
import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../http-common";
import httpMedia from "../../http-common-media";
import { RuleCancelled24, SaveModel24 } from "@carbon/icons-react";
import { sellerProductSchema } from "../../services/SchemaService";
import { AuthContext } from "../../contexts/AuthContext";
import { yupResolver } from "@hookform/resolvers/yup";
import IFrameDocument from "../Home/Documents";

const ProductForm = ({ mode, url, successMessage, onSucessMessage }) => {
    const [formMode] = useState(mode);
    const [showSuccess, setShowSuccess] = useState(false);
    const [productCategories, setProductCategories] = useState([]);
    const [productCategory, setProductCategory] = useState({});
    const [allProducts, setAllProducts] = useState([]);
    const [initalProductCategory, setInitialProdCategory] = useState('');
    const [seedProvideController, setSeedProvideController] = useState('');

    const [filteredProducts, setFilteredProducts] = useState([]);
    const [productID, setProductID] = useState({
        id: ''
    });
    const [sellerID, setSellerID] = useState({ id: null });

    const [sellerProduct, setSellerProduct] = useState({
        category: '',
        productId: '',
        sellerId: '',
        productType: '',
        price: '',
        quantity: '',
        measurementUnit: '',
        optionalDescription: '',
        productDescription: '',
        lotNumber: '',
        providerName: seedProvideController,
    });
    const [media, setMedia] = useState({});
    const [mediaReceipt, setMediaReceipt] = useState({});
    const [showBtnSeed, setShowBtnSeed] = useState(true);
    const [showUtilsFiled, setShowUtilsFiled] = useState(true);

    const [showBtnReceipt, setShowBtnReceipt] = useState(true);
    const [mediaStatus, setMediaStatus] = useState();
    const [mediaReceiptStatus, setMediaReceiptStatus] = useState();
    const [productName, setProductName] = useState({ description: "" });
    const [isSeedClicked, setSeddClicked] = useState(mode === "UPDATE" ? true : false);
    const [isReceiptClicked, setReceiptClicked] = useState(mode === "UPDATE" ? true : false);
    const [showLoading, setShowLoading] = useState(false);

    const getSchema = () => {
        switch (mode) {
            case "CREATE":
                return sellerProductSchema;
            case "UPDATE":
                return sellerProductSchema;
            case "CREATE-NEW":
                return sellerProductSchema;
            default:
                return sellerProductSchema;
        }
    };

    const threshold = async (e) => {

        sellerProduct.quantity = e.target.value;
        setValue("quantity", e.target.value);
        setValue("seedFile", true);
        setValue("receipt", true);

        switch (productCategory.selectedItem.text) {
            case "Sementes":
                const seedsLimit = {
                    'Kilos (kg)': 1,
                    'Gramas (g)': 1000,
                };
                if (sellerProduct.quantity > seedsLimit[sellerProduct.measurementUnit]) {
                    toast.error('A quantidade não pode ser maior que ' + seedsLimit[sellerProduct.measurementUnit] + " " + sellerProduct.measurementUnit);
                    setValue("threshold", null);

                    return "";
                } else {
                    setValue("threshold", true);

                }

                return "";
            case "Utensilios":
                const utilsLimit = {
                    'Uma unidade': 1,
                };
                if (sellerProduct.quantity > utilsLimit[sellerProduct.measurementUnit]) {
                    toast.error('A quantidade não pode ser maior que ' + utilsLimit[sellerProduct.measurementUnit] + " " + sellerProduct.measurementUnit);
                    setValue("threshold", null);

                    return "";
                } else {
                    setValue("threshold", true);

                }
                return "";
            case "Fertilizantes":
                const fertilizantesLimit = {
                    'Kilos (kg)': 10,
                };
                if (sellerProduct.quantity > fertilizantesLimit[sellerProduct.measurementUnit]) {
                    toast.error('A quantidade não pode ser maior que ' + fertilizantesLimit[sellerProduct.measurementUnit] + " " + sellerProduct.measurementUnit);
                    setValue("threshold", null);

                    return "";
                } else {
                    setValue("threshold", true);

                }

                return "";
            case "Pesticidas":
                const pestSeedLimit = {
                    'Kilos (kg)': 1,
                    'Litros (l)': 1,
                };
                if (sellerProduct.quantity > pestSeedLimit[sellerProduct.measurementUnit]) {
                    toast.error('A quantidade não pode ser maior que ' + pestSeedLimit[sellerProduct.measurementUnit] + " " + sellerProduct.measurementUnit);
                    setValue("threshold", null);

                    return "";
                } else {
                    setValue("threshold", true);

                }

                return "";
        }
    };
    const authContext =
        useContext(AuthContext);

    const { register, handleSubmit, reset, setValue, getValues, watch, formState: { errors }, } = useForm({ resolver: yupResolver(getSchema()), mode: "onSubmit" }); //
    const navigate = useNavigate();
    const params = useParams();
    const retriveProductCategories = async () => {
        const response = await http.get('/productCategories');

        setProductCategories(response.data.map(r => { return { id: r.id, text: r.description } }));
    };
    const retrieveAllProducts = async () => {
        const response = await http.get('/productsx/approved');

        setAllProducts(response.data);
    };
    const onProductCategoryChange = (category) => {
        setProductCategory(category);
        setValue("category", category.selectedItem.text);
        setInitialProdCategory(category.selectedItem.text);
        const filtered = allProducts.filter(p => p.categoryId == category.selectedItem.id);

        setFilteredProducts(filtered.map(p => { return { id: p.id, text: p.description + " " + p.productType } }));
        if (category.selectedItem.text === "Utensilios") {
            sellerProduct.measurementUnit = "Uma unidade";
            setValue("measurementUnit", "Uma unidade");
            setValue("quantity", 1);
            setShowUtilsFiled(false);
            setValue("threshold", true);

        }
        if (category.selectedItem.text != "Sementes") {
            setValue("lotNumber", " ");
            setValue("providerName", " ");


        }
    };
    const retrieveSellerProducts = async id => {
        const response = await http.get('/sellerProduct/' + id);
        var sellerProductObj = {
            'measurementUnit': response.data.measurementUnit,
            'optionalDescription': response.data.optionalDescription,
            'price': response.data.price,
            'productDescription': response.data.product.description + " " + response.data.product.productType,
            'quantity': response.data.quantity,
            'sellerId': response.data.seller.id,
            'categoryDescription': response.data.product.category.description,
            'productType': response.data.product.productType,
            'category': response.data.product.category.description,
            'lotNumber': response.data.lotNumber,
            'providerName': response.data.providerName,

        };
        setInitialProdCategory(response.data.product.category.description);
        setSellerProduct(sellerProductObj);
        const fields = [
            'measurementUnit',
            'optionalDescription',
            'price',
            'productDescription',
            'quantity',
            'sellerId',
            'categoryDescription',
            'productType',
            'category',
            'lotNumber',
            'providerName',
        ];
        fields.forEach((field) => setValue(field, sellerProductObj[field]));
        setSellerID({ ...sellerID, id: sellerProductObj.sellerId });
        setValue('productId', response.data.product.id);
        setValue("threshold", true);
        setValue("seedFile", true);
        setValue("receipt", true);
        if (response.data.product.category.description != "Sementes") {
            setValue("lotNumber", " ");
            setValue("providerName", " ");


        }

        return response;
    };
    const retrieveMediaSellerProduct = async (sellerId, sellerProductId) => {
        const response = await http.get('/media/seddscertificate/' + sellerId + "/" + sellerProductId)
            .then((response) => {
                setMediaStatus(response.status);
                setMedia(response.data);
                setShowBtnSeed(false);
                setValue("threshold", true);

            })
            .catch((error) => {
                console.log(error);
            });

        const responseReceipt = await http.get('/media/purchasereceipt/' + sellerId + "/" + sellerProductId)
            .then((response) => {
                setMediaReceiptStatus(response.status);
                setMediaReceipt(response.data);
                setShowBtnSeed(false);
                setValue("threshold", true);

            })
            .catch((error) => {
                console.log(error);
            });

    };
    const onSubmit = async (data) => {
        try {
            setShowLoading(true);

            // const response= await http.post(url,data);
            if (formMode === "UPDATE") {
                const response = await http.post('sellerProduct/' + params.sellerProductId, data);
                if (response.status == 200 && response.data.product.category.description == "Sementes") {

                    const responseMedia = await httpMedia.post('media/upload/seddscertificate/' + authContext.sellerID + '/' + params.sellerProductId, media);
                    const responseMediaReceipt = await httpMedia.post('media/upload/purchasereceipt/' + authContext.sellerID + '/' + params.sellerProductId, mediaReceipt);

                }

            } else {
                const response = await http.post('sellerproducts/' + authContext.sellerID + '/' + productID.id, data);
                if (response.status == 200 && initalProductCategory === "Sementes") {
                    const responseMedia = await httpMedia.post('media/upload/seddscertificate/' + authContext.sellerID + '/' + response.data.id, media);
                    const responseMediaReceipt = await httpMedia.post('media/upload/purchasereceipt/' + authContext.sellerID + '/' + response.data.id, mediaReceipt);

                    console.log(responseMediaReceipt);
                }


            }
            setShowLoading(false);
            toast.success(successMessage);
            setShowSuccess(true);
            navigate('../detalhes-do-fornecedor', { replace: true });
        } catch (error) {
            toast.error(error.response?.data.message);
        }
    };
    const setProduct = async (e) => {
        sellerProduct.productId = e.selectedItem.id;
        sellerProduct.productDescription = e.selectedItem.text;
        setValue("productDescription", e.selectedItem.text);
        setValue("productId", e.selectedItem.id);
        setProductID({ ...productID, id: e.selectedItem.id });
    };
    const setProductLabel = () => {
        console.log("productName", getValues("productName") ?? "");
        // sellerProduct.productId = e.selectedItem.id;
        // sellerProduct.productDescription = e.selectedItem.text;
        // setProductName({...productName,name:e.selectedItem.text});
    };
    const onMeasurementUnitChange = (e) => {
        sellerProduct.measurementUnit = e.selectedItem.text;
        setValue("measurementUnit", e.selectedItem.text);
    };
    const onProviderNameChange = (e) => {
        sellerProduct.providerName = e.selectedItem.text;
        setSeedProvideController(e.selectedItem.text);
        setValue("providerName", e.selectedItem.text);
    };
    const getProductDescription = () => {
        return ""
            .concat(watch("productDescription") ?? "")
            .concat(sellerProduct.productDescription)
            .concat(" ")
            .concat(sellerProduct.productType == "Outros" ? "" : sellerProduct.productType)
            .concat(watch("quantity"))
            .concat(" ")
            .concat(watch("optionalDescription"))
            .concat(" ")
            .concat(sellerProduct.measurementUnit);
    };
    const onFileChange = event => {



        const file = window.URL.createObjectURL(event.target.files[0]);
        const formData = new FormData();
        formData.append(
            "file",
            event.target.files[0],
            event.target.files[0].name
        );
        setMedia(formData);
        setSeddClicked(true);
        setValue("seedFile", true);
        const iframe = document.querySelector("iframe");
        if (iframe?.src) iframe.src = file;
    };

    const onFileReceiptChange = event => {



        const file = window.URL.createObjectURL(event.target.files[0]);
        const formData = new FormData();
        formData.append(
            "file",
            event.target.files[0],
            event.target.files[0].name
        );
        setMediaReceipt(formData);
        setReceiptClicked(true);
        setValue("receipt", true);

    };
    const openSeedDocument = () => {
        navigate("../view-doc", { state: { documentID: media.id } });
    };
    const providerLote = [
        {
            id: '1',
            text: 'TECAP'
        },
        {
            id: '2',
            text: 'AGRIFOCUS'
        },
        {
            id: '3',
            text: 'MOZASEM'
        }, {
            id: '4',
            text: 'MINDZO COMERCIAL'
        }, {
            id: '5',
            text: 'SOLUCOES RURAIS'
        }, {
            id: '6',
            text: 'LUSOSEM'
        }, {
            id: '7',
            text: 'CHUNGUERU INSUMOS'
        }, {
            id: '8',
            text: 'CASA DO AGRICULTOR HOME, LDA'
        }, {
            id: '9',
            text: 'SYNGENTA AGROSERVICE'
        }, {
            id: '10',
            text: 'SEED CO INTERNATIONAL MOZAMBIQUE LDA'
        }, {
            id: '11',
            text: 'Munguambe & Filhos'
        }, {
            id: '12',
            text: 'ETC ADUBOS'
        }, {
            id: '13',
            text: 'MOZGRAIN LDA'
        }, {
            id: '14',
            text: 'Nhamadzi Comercial'
        }, {
            id: '15',
            text: 'DENGO COMERCIAL'
        }, {
            id: '16',
            text: 'MANICA MBEU'
        }, {
            id: '18',
            text: 'IAV'
        }, {
            id: '19',
            text: 'PHOENIX SEED'
        }, {
            id: '20',
            text: 'KLEIN KAROO SEED MARKETING'
        }, {
            id: '21',
            text: 'Companhia de Zembe'
        }, {
            id: '22',
            text: 'Sementes Nzara Yapera'
        }, {
            id: '23',
            text: 'PRODECA'
        }, {
            id: '24',
            text: 'INSTITUTO SUPERIOR POLITECNICO DE MANICA'
        }, {
            id: '25',
            text: 'Emilia Comercial'
        }, {
            id: '26',
            text: 'SID Mocambique'
        }, {
            id: '27',
            text: 'MATUEL COMERCIAL'
        }, {
            id: '28',
            text: 'AGRO COMERCIAL, OLINDA FONDO'
        }, {
            id: '29',
            text: 'Agro Zambeze'
        }, {
            id: '30',
            text: 'JNB - Empreendimentos'
        },
        {
            id: '31',
            text: 'AGRODALTON'
        },
        {
            id: '17',
            text: 'Plexus Farming Mozambique Lda'
        },
        {
            id: '32',
            text: 'Bayer'
        },
        {
            id: '33',
            text: 'Easi seeds'
        },
        {
            id: '34',
            text: 'Outro'
        },
    ];
    const measuretUnitValuesByCategory = {
        "": [],
        "Sementes": [
            {
                id: '1',
                text: 'Gramas (g)'
            },
            {
                id: '2',
                text: 'Kilos (kg)'
            },
        ],
        "Utensilios": [{
            id: '1',
            text: 'Uma unidade'
        }],
        "Fertilizantes": [
            //     {
            //     id: '1',
            //     text: 'Gramas (g)'
            // },
            {
                id: '2',
                text: 'Kilos (kg)'
            },
            // {
            //     id: '3',
            //     text: 'Litros (l)'
            // },
        ],
        "Pesticidas": [
            //     {
            //     id: '1',
            //     text: 'Gramas (g)'
            // },
            {
                id: '2',
                text: 'Kilos (kg)'
            }, {
                id: '3',
                text: 'Litros (l)'
            },]
    };
    useEffect(() => {
        retriveProductCategories();

        retrieveAllProducts();
        if (formMode === "UPDATE") {
            try {
                retrieveSellerProducts(params.sellerProductId);
                retrieveMediaSellerProduct(authContext.sellerID, params.sellerProductId);

            } catch (error) {
                toast.error(error);
            }
        }
        setShowSuccess(false);
    }, [formMode]);

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Column>
                        {
                            formMode === "UPDATE" ?
                                <Dropdown
                                    id="category"
                                    titleText="Categoria"
                                    label={sellerProduct.category}
                                    items={productCategories}
                                    disabled
                                    {...register("category")}
                                    onChange={onProductCategoryChange}
                                    name="category"
                                    itemToString={item => (item ? item.text : '')}
                                /> : <Dropdown
                                    id="category"
                                    titleText="Categoria"
                                    label="Selecione"
                                    items={productCategories}
                                    {...register("category")}
                                    onChange={onProductCategoryChange}
                                    name="category"
                                    itemToString={item => (item ? item.text : '')}
                                />
                        }

                        <p className="field-error">{errors.category?.message}</p>
                    </Column>

                    {
                        formMode === "CREATE-NEW" ? <Column>
                            <TextInput
                                id="productDescription"
                                labelText="Nome do Produto"
                                name="productDescription"
                                {...register("productDescription")}
                            />
                        </Column> : <Column>
                            {
                                formMode === "UPDATE" ? <Dropdown
                                    id="productId"
                                    titleText="Produto"
                                    label={sellerProduct.productDescription}
                                    items={productCategories}
                                    disabled
                                    {...register("productId")}
                                    onChange={setProduct}
                                    name="productId"
                                    itemToString={item => (item ? item.text : '')}
                                /> :
                                    <ComboBox
                                        id="productId"
                                        titleText="Produto"
                                        label="Selecione"
                                        items={filteredProducts}
                                        onChange={setProduct}
                                        name="productId"
                                        itemToString={item => (item ? item.text : '')}
                                    />
                            }

                            <p className="field-error">{errors.productId?.message}</p>

                        </Column>

                    }

                </Row>
                <Row>
                    <Column>
                        {
                            formMode === "UPDATE" ? <Dropdown
                                id="measurementUnit"
                                titleText="Unidade de Medida"
                                label={sellerProduct.measurementUnit}
                                items={measuretUnitValuesByCategory[initalProductCategory]}
                                onChange={onMeasurementUnitChange}
                                name="measurementUnit"
                                itemToString={item => (item ? item.text : '')}
                            /> : <Dropdown
                                id="measurementUnit"
                                titleText="Unidade de Medida"
                                label="Selecione"
                                items={measuretUnitValuesByCategory[initalProductCategory]}
                                onChange={onMeasurementUnitChange}
                                name="measurementUnit"
                                itemToString={item => (item ? item.text : '')}
                            />
                        }

                        <p className="field-error">{errors.measurementUnit?.message}</p>
                    </Column>
                    <Column>
                        <TextInput
                            id="quantity"
                            labelText="Quantidade Unitaria"
                            // {...register("quantity")}
                            onChange={threshold}

                        />
                        <p className="field-error">{errors.quantity?.message}</p>
                        <p className="field-error">{errors.threshold?.message}</p>

                    </Column>
                </Row>

                <Row>
                    <Column>
                        <TextInput
                            id="price"
                            labelText="Preço do Produto"
                            {...register("price")}
                        />
                        <p className="field-error">{errors.price?.message}</p>
                    </Column>
                    <Column>
                        <TextInput
                            id="optionalDescription"
                            labelText="Descrição Adicional (opcional)"
                            {...register("optionalDescription")}
                            name="optionalDescription"
                        />
                    </Column>
                </Row>
                {initalProductCategory == "Sementes" ? <Row>
                    {/* {
                        sellerProduct.providerName == "Outro" || sellerProduct.providerName == "outro" ? <Column>
                            {
                                formMode === "UPDATE" ? <Dropdown
                                    id="providerName"
                                    titleText="Fornecedor"
                                    label={sellerProduct.providerName}
                                    items={providerLote}
                                    onChange={onProviderNameChange}
                                    name="providerName"
                                    itemToString={item => (item ? item.text : '')}
                                /> :
                                <TextInput
                                id="providerName"
                                labelText="Nome do Fornecedor"
                                // {...register("quantity")}
                                onChange={onProviderNameChange}
    
                            />
                                // <Dropdown
                                //     id="providerName"
                                //     titleText="Fornecedor"
                                //     label="Selecione"
                                //     items={providerLote}
                                //     onChange={onProviderNameChange}
                                //     name="providerName"
                                //     itemToString={item => (item ? item.text : '')}
                                // />
                            }

                            <p className="field-error">{errors.providerName?.message}</p>
                        </Column> : */}
                    <Column>
                        {
                            formMode === "UPDATE" ? <Dropdown
                                id="providerName"
                                titleText="Fornecedor"
                                label={sellerProduct.providerName}
                                items={providerLote}
                                onChange={onProviderNameChange}
                                name="providerName"
                                itemToString={item => (item ? item.text : '')}
                            /> : <Dropdown
                                id="providerName"
                                titleText="Fornecedor"
                                label="Selecione"
                                items={providerLote}
                                onChange={onProviderNameChange}
                                name="providerName"
                                itemToString={item => (item ? item.text : '')}
                            />

                        }
                        {
                            seedProvideController == "Outro" || seedProvideController == "outro" ?
                                <TextInput
                                    id="providerName"
                                    labelText="Nome do Fornecedor"
                                    name="providerName"
                                    {...register("providerName")}
                                />
                                // <TextInput
                                //     id="providerName"
                                //     labelText="Nome do Fornecedor"
                                //     // {...register("quantity")}
                                //     onChange={onProviderNameChange}

                                // /> 
                                : ""
                        }

                        <p className="field-error">{errors.providerName?.message}</p>
                    </Column>
                    {/* // } */}

                    <Column>
                        <TextInput
                            id="lotNumber"
                            labelText="Numero de Lote"
                            {...register("lotNumber")}
                        />
                        <p className="field-error">{errors.lotNumber?.message}</p>
                    </Column>
                </Row> : ""}
                <Row>
                    <Column>
                        {initalProductCategory == "Sementes" && showBtnSeed === true ?
                            <div className="cds--file__container">
                                <FileUploader
                                    accept={[
                                        '.jpg',
                                        '.pdf'
                                    ]}
                                    buttonKind="tertiary"
                                    buttonLabel="Carregar Certificado"
                                    filenameStatus="edit"
                                    iconDescription="Adding file"
                                    labelDescription="O tamanho maximo é de 20MB"
                                    labelTitle="Somente o certificado de sementes"
                                    onChange={onFileChange}
                                />
                            </div>
                            : ""}
                        {initalProductCategory == "Sementes" && mediaStatus === 200 ?
                            <div className="cds--file__container">
                                <br />
                                <IFrameDocument
                                    mediaId={media.id}
                                    documentName={"Ver Certificado "}
                                />

                            </div>


                            : ""}

                        {
                            formMode === "UPDATE" && initalProductCategory == "Sementes" ?

                                <div className="cds--file__container">
                                    <FileUploader
                                        accept={[
                                            '.jpg',
                                            '.pdf'
                                        ]}
                                        buttonKind="tertiary"
                                        buttonLabel="Carregar Certificado"
                                        filenameStatus="edit"
                                        iconDescription="Adding file"
                                        labelDescription="O tamanho maximo é de 20MB"
                                        labelTitle="Somente o certificado de sementes"
                                        onChange={onFileChange}
                                    />
                                </div> : ""
                        }
                        <p className="field-error">{errors.seedFile?.message}</p>

                        {initalProductCategory == "Sementes" ? <iframe src="" width="50%" height="50%"></iframe> : ""}
                    </Column>
                    <Column>
                        {initalProductCategory == "Sementes" && showBtnSeed === true ?
                            <div className="cds--file__container">
                                <FileUploader
                                    accept={[
                                        '.jpg',
                                        '.pdf'
                                    ]}
                                    buttonKind="tertiary"
                                    buttonLabel="Carregar Recibo de Compra"
                                    filenameStatus="edit"
                                    iconDescription="Adding file"
                                    labelDescription="O tamanho maximo é de 20MB"
                                    labelTitle="Somente o Recibo de Compra"
                                    onChange={onFileReceiptChange}
                                />
                            </div>
                            : ""}
                        {initalProductCategory == "Sementes" && mediaReceiptStatus === 200 ?
                            <div className="cds--file__container">
                                <br />
                                <IFrameDocument
                                    mediaId={mediaReceipt.id}
                                    documentName={"Ver Recibo de Compra "}
                                />



                            </div>


                            : ""}

                        {
                            formMode === "UPDATE" && initalProductCategory == "Sementes" ?
                                <FileUploader
                                    accept={[
                                        '.jpg',
                                        '.pdf'
                                    ]}
                                    buttonKind="tertiary"
                                    buttonLabel="Carregar Recibo de Compra"
                                    filenameStatus="edit"
                                    iconDescription="Adding file"
                                    labelDescription="O tamanho maximo é de 20MB"
                                    labelTitle="Somente o Recibo de Compra"
                                    onChange={onFileReceiptChange}
                                /> : ""
                        }
                        <p className="field-error">{errors.receipt?.message}</p>

                        {initalProductCategory == "Sementes" ? <iframe src="" width="50%" height="50%"></iframe> : ""}

                    </Column>
                </Row>

                <Row>
                    <Column>
                    </Column>
                    <Column>
                        <span>{"Nome final:" + getProductDescription()}</span>

                    </Column>

                </Row>
                <Row>
                    <Column>
                        <ButtonSet>
                            <Button
                                type="submit"
                                renderIcon={SaveModel24}
                                iconDescription="Save"
                            >
                                Salvar
                                {
                                    showLoading == true ?
                                        <Loading
                                            description="Active loading indicator" withOverlay={false}
                                            small /> : ""
                                }
                            </Button>
                        </ButtonSet>
                    </Column>
                </Row>
            </Form>
        </>
    );
};
export default ProductForm;