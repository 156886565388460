import React from "react";
import {
  Button,
  Form,
  TextInput,
  PasswordInput,
} from "carbon-components-react";
import { ArrowRight24, CheckboxChecked24 } from "@carbon/icons-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import http from "../../http-common";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Card from "../../components/Card/Card";
import UserForm from "./UserForm";

const requiredMessage = "Campo obrigatório";
const invalidNuit = "NUIT inválido";

const userSchema = yup.object({
  firstName: yup.string().required(requiredMessage),
  lastName: yup.string().required(requiredMessage),
  username: yup
    .string()
    .required(requiredMessage)
    .length(9, invalidNuit)
    .matches(/^[0-9]*$/, invalidNuit),
  email: yup.string().required(requiredMessage).email("Email inválido"),
  password: yup.string().required(requiredMessage),
});

const SignupPage = () => {
  return (
    <div className="bx--row">
      <div className="bx--col-lg-6 content">
      </div>
      <div className="bx--col-sm-12 bx--col-lg-6">
        <Card title="Regista-te">
          <UserForm
            mode={"SIGNUP"}
            url={"/users"}
            successMessage="Conta Criada com sucesso"
            onSuccessNavigateTo={"/"}
          />
        </Card>
      </div>
    </div>
  );
};

export default SignupPage;
