import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Column,
  Dropdown,
  Row,
  Form,
  TextInput,
  TextArea,
  Select,
  SelectItem,
} from "carbon-components-react";

import ArrowRight24 from "@carbon/icons-react/es/arrow--right/24";
import http from "../../http-common";
import { grievanceSchema } from "../../services/SchemaService";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useGet from "../../hooks/useGet";
import Card from "../../components/Card/Card";
import { PasswordInput } from "carbon-components-react";

const SubmitGrievancePage = (props) => {
  const { data: provinces } = useGet("/utils/provinces");
  const { data: genders } = useGet("/utils/genders");
  const { data: types } = useGet("/utils/grievance-types");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(grievanceSchema) });

  const navigate = useNavigate();

  const [districts, setDistricts] = useState([]);
  const [occurrenceNumber, setOccurrenceNumber] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [gData, setGData] = useState({
    occurrenceNumber: "",
    accessCode: "",
  });

  const handleProvinceChange = async (e) => {
    if (e.target.value) {
      try {
        const response = await http.get(
          "/utils/provinces/" + e.target.value + "/districts"
        );
        setDistricts(response.data);
      } catch (error) {
        toast.error(error?.response?.data.message);
      }
    } else {
      setDistricts([]);
    }
  };

  useEffect(() => {
    // retrieveProvinces();
  }, []);

  const onSubmit = async (data) => {
    try {
      if (data.gender === "") {
        data.gender = null;
      }
      const response = await http.post("/grievances", data);

      toast.success("Reclamação submetida com sucesso");
      setOccurrenceNumber(response.data.occurrenceNumber);
      setAccessCode(response.data.accessCode);
      setSubmitted(true);
      // navigate("../visualizar-candidatura/" + response.data.uuid);
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  };

  const setValues = (e) => {
    e.persist();
    setGData({ ...gData, [e.target.name]: e.target.value });
  };

  const handleTrackGrievance = async (e) => {
    e.preventDefault();
    try {
      const response = await http.get(
        `grievances/track?occurrenceNumber=${gData.occurrenceNumber}&accessCode=${gData.accessCode}`
      );

      navigate("../verificar-reclamacao/" + gData.occurrenceNumber);
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  };
  // const setOccurrenceData = async (e) => {
  //   console.log("xxxxx");
  //   setOccurrenceNumber("xxxxxxxxxx");
  //   props.history.push({
  //     pathname: "/ocorrencia",
  //     state: occurrenceNumber,
  //   });
  // };

  return (
    <div className="bx--row">
      <div className="bx--col-lg-5">
        <div className="content">
          <h1 className="title">Expressa-te</h1>
          <h5>Tens alguma Queixa ou Reclamação a apresentar?</h5>
          <p className="mt-1">
            Os usuários e beneficiários do Agora Emprega, parceiros e outros
            interessados podem apresentar reclamações e/ou queixas caso se
            sintam lesados em qualquer etapa do Agora Emprega. Estas queixas
            podem ser apresentadas por via digital, por carta física ou por
            telefone por meio de chamada, SMS ou Whatsapp para os seguintes
            números de telefone:
          </p>
          <p>
            <strong>85 730 5231 / 87 730 5236 </strong>
          </p>
          <p className="mt-1 mb-1">
            Toda e qualquer reclamação e queixa recebida é tratada com
            confidencialidade e, se requerido pelo reclamante, em anonimato.
          </p>
        </div>

        <Card title="Verificar Reclamação">
          <p>Verifica aqui o estado da tua reclamação</p>
          <Form onSubmit={handleTrackGrievance}>
            <Row>
              <Column sm={6} md={6} lg={6} className="required">
                <TextInput
                  id="occurrenceNumber"
                  name="occurrenceNumber"
                  labelText="Número de Ocorrência"
                  placeholder=""
                  maxLength={8}
                  required
                  onChange={setValues}
                />
                <p className="field-error">{errors.username?.message}</p>
              </Column>
              <Column sm={6} md={6} lg={6} className="required">
                <TextInput
                  id="accessCode"
                  name="accessCode"
                  labelText="Código de acesso"
                  placeholder=""
                  maxLength={4}
                  required
                  onChange={setValues}
                />
                <p className="field-error">{errors.username?.message}</p>
              </Column>
            </Row>
            <Button
              type="submit"
              renderIcon={ArrowRight24}
              className="mt-2 btn-fluid"
            >
              Entrar
            </Button>
          </Form>
        </Card>
      </div>
      <div className="bx--col-lg-7 mt-2">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Submeter Reclamação</h3>
          </div>
          <div className="card-body">
            <div className="bx--row">
              <div className="bx--col">
                {submitted ? (
                  <div className="emprega-green">
                    <h3>Obrigado por te expressares</h3>
                    <p>
                      Para acompanhares o ponto de situação da tua preocupação,
                      usa o número de ocorrencia {occurrenceNumber} e o código
                      de acesso {accessCode}
                    </p>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      <Row>
                        <Column sm={12} md={12} lg={12}>
                          <TextInput
                            id="name"
                            labelText="Nome"
                            {...register("name")}
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column sm={12} md={6} lg={6}>
                          <TextInput
                            id="age"
                            labelText="Idade"
                            {...register("age")}
                          />
                        </Column>
                        <Column sm={12} md={6} lg={6}>
                          <Select
                            id="gender"
                            labelText="Genero"
                            {...register("gender")}
                          >
                            <SelectItem text="" value="" />
                            {genders.map((p) => (
                              <SelectItem
                                text={p.description}
                                value={p.code}
                                key={p.code}
                              />
                            ))}
                          </Select>
                        </Column>
                      </Row>
                      <Row>
                        <Column sm={12} md={6} lg={6}>
                          <TextInput
                            id="email"
                            labelText="Email"
                            {...register("email")}
                          />
                        </Column>
                        <Column sm={12} md={6} lg={6}>
                          <TextInput
                            id="phone"
                            labelText="Telefone"
                            {...register("phone")}
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column xml={12} sm={6} md={6} lg={6}>
                          <Select
                            id="provinceId"
                            labelText="Província"
                            {...register("provinceId")}
                            onChange={handleProvinceChange}
                          >
                            <SelectItem text="" value="" />
                            {provinces.map((p) => (
                              <SelectItem
                                text={p.description}
                                value={p.id}
                                key={p.id}
                              />
                            ))}
                          </Select>
                        </Column>

                        <Column xml={12} sm={6} md={6} lg={6}>
                          <Select
                            id="districtId"
                            name="districtId"
                            labelText="Cidade/Distrito"
                            // value={application?.districtId || ""}
                            {...register("districtId")}
                          >
                            <SelectItem text="" value="" />
                            {districts.map((p) => (
                              <SelectItem
                                text={p.description}
                                value={p.id}
                                key={p.id}
                              />
                            ))}
                          </Select>
                        </Column>
                      </Row>

                      <Row>
                        <Column sm={2} md={6} lg={6} className="required">
                          <Select
                            id="type"
                            labelText="Tipo"
                            {...register("type")}
                          >
                            <SelectItem text="" value="" />
                            {types.map((p) => (
                              <SelectItem
                                text={p.description}
                                value={p.code}
                                key={p.code}
                              />
                            ))}
                          </Select>
                          <p className="field-error">{errors.type?.message}</p>
                        </Column>
                        <Column sm={2} md={6} lg={6} className="required">
                          <TextInput
                            id="subjuct"
                            labelText="Assunto"
                            {...register("subject")}
                          />
                          <p className="field-error">
                            {errors.subject?.message}
                          </p>
                        </Column>
                      </Row>

                      <Row>
                        <Column sm={12} md={12} lg={12} className="required">
                          <TextArea
                            id="description"
                            labelText="Descrição"
                            enableCounter
                            maxCount={1250}
                            {...register("description")}
                          />
                          <p className="field-error">
                            {errors.description?.message}
                          </p>
                        </Column>
                      </Row>
                      <Row>
                        <Column sm={12} md={4} lg={4}>
                          <Button
                            type="submit"
                            renderIcon={ArrowRight24}
                            className="mt-2"
                          >
                            Submeter
                          </Button>
                        </Column>
                      </Row>
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmitGrievancePage;
