import React from "react";
import ApprovalPage from "./ApprovalPage";

const RejectedApprovals = () => {
    return (
        <>
            <ApprovalPage
                status={1}
            />
        </>


    );
};

export default RejectedApprovals;