import React from "react";

const NotFoudPage = () => {
  return (
    <div>
      <h1>404 - Not found</h1>
    </div>
  );
};

export default NotFoudPage;
