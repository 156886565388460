import React from "react";
import Card from "../../components/Card/Card";
import UserForm from "./UserForm";

const CreateUserPage = () => {


  return (
    <Card title="Registar Utilizador">
      <UserForm
        mode={"CREATE"}
        url={"/auth/signup/"}
        successMessage={"Conta criada com sucesso"}
        onSuccessNavigateTo={"../utilizadores"}
      />
    </Card>
  );
};

export default CreateUserPage;
