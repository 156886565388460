import React, { useState, useEffect } from "react";
import { useContext } from "react";
import CustomTable from "../../components/custom-table/custom-table";
import { AuthContext } from "../../contexts/AuthContext";
import http from "../../http-common";
import { Button } from "carbon-components-react";
import { Document16 } from "@carbon/icons-react";
import jsPDF from "jspdf";
import "jspdf-autotable";


const tableHeaders = [
    { key: 'firstname', header: 'Nome' },
    { key: 'lastname', header: 'Sobrenome' },
    { key: 'username', header: 'Usuário' },
    { key: 'email', header: 'email' },
    { key: 'textPassword', header: 'Senha' },
];

const ProviderUsersPage = () => {

    const [users, setUsers] = useState([]);

    const authContext = useContext(AuthContext);
    const sellerID = localStorage.getItem("sellerID");

    const fetchProviderUsers = async (id) => {
        const response = await http.get('/usersx/' + id);

        setUsers(response.data);

        return response.data;
    };

    const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 200;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Credencias do Fornecedor";
        const headers = [["Fornecedor", "Username", "Password"]];

        const data = users.map(

            user => [user.firstname, user.username, user.textPassword]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("users.pdf")
    }

    const exportUsers = (

        <div className="mb-2 form-buttons">
            <Button
                onClick={exportPDF}
                renderIcon={Document16}
            >
                Exportar Usuários
            </Button>
        </div>
    );

    useEffect(() => {
        fetchProviderUsers(sellerID);
    }, []);



    return (
        <>
            <div style={{ marginTop: '3rem', backgroundColor: '#fff' }}>
                <CustomTable
                    tableTitle="Utilizadores"
                    tableHeaders={tableHeaders}
                    tableRows={users}
                    addButton={exportUsers}
                />
            </div>
        </>
    )
}

export default ProviderUsersPage;