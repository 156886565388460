import Card from "../../components/Card/Card";
import FairForm from "./FairForm";

const UpdateFairPage=()=>{

    return ( 
        <Card
        title="Editar Feira"
        > 
        <FairForm 
        mode={"UPDATE"}
        url={"fairs/"}
        sucessMessage={"Feira atualizada com sucesso"}
        onSuccessNavigateTo={"../inicio"}
           
        />
        </Card>
    );
};
export default UpdateFairPage;
