import { DataView24, Edit24 } from "@carbon/icons-react";
import { Add16 } from "@carbon/icons-react";
import {
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} from "carbon-components-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card/Card";
import TablePagination from "../../components/Pagination/Pagination";
import http from "../../http-common";

const tableHeaders = [
  {
    header: "Nome",
    key: "name",
  },
  {
    header: "Telefone",
    key: "phone",
  },
  {
    header: "Email",
    key: "email",
  },
  {
    header: "Tipo",
    key: "type",
  },
  {
    header: "Assunto",
    key: "subject",
  },
  {
    header: " ",
    key: "actions",
  },
];

const LinkList = ({ url }) => (
  <ul style={{ display: "flex" }}>
    <li>
      <Link to={url} title="Consultar">
        <DataView24 />
      </Link>
    </li>
  </ul>
);

const getCellContent = (cell, id) => {
  if (cell.info.header === "actions") {
    return (
      <LinkList
        url={"../consultar-reclamacao/" + id}
      />
    );
  }
  return cell.value;
};

const ListGrievancesPage = () => {
  const [apps, setApps] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);

  const navigate = useNavigate();

  const featchData = async () => {
    try {
      const response = await http.get("/grievances/search/q?size=" + size);
      const data = response.data.content.map((g) => ({
        id: g.occurrenceNumber,
        name: g.name,
        phone: g.phone,
        email: g.email,
        type: g.type.description,
        subject: g.subject,
      }));
      setApps(data);
      setCount(response.data.totalElements);
    } catch (error) { }
  };

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1;
      const response = await http.get("/grievances/search/q?page=" + pag + "&size=" + pageSize);
      const data = response.data.content.map((g) => ({
        id: g.occurrenceNumber,
        name: g.name,
        phone: g.phone,
        email: g.email,
        type: g.type.description,
        subject: g.subject,
      }));
      setApps(data);
    } catch (error) { }
  };

  const searchTerm = async (phone) => {
    try {
      const response = await http.get("/grievances/search/q?phone=" + phone);
      const data = response.data.content.map((g) => ({
        id: g.occurrenceNumber,
        name: g.name,
        phone: g.phone,
        email: g.email,
        type: g.type.description,
        subject: g.subject,
      }));
      setApps(data);
    } catch (error) { }
  };

  useEffect(() => {
    featchData();
  }, []);

  useEffect(() => {
      apps.filter(a =>
        a.nuit
      )
  }, [apps]);

  return (
    <Card title="Reclamações">
      <DataTable rows={apps} headers={tableHeaders} isSortable useZebraStyles>
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <TableToolbar aria-label="data table toolbar">
              <TableToolbarContent>
                <TableToolbarSearch
                  placeholder="Digite o número de telefone para pesquisar"
                  onChange={e => searchTerm(e.target.value)}
                />
                <Button
                  onClick={() => navigate("../consultar-reclamacao")}
                  renderIcon={Add16}
                >
                  Nova Candidatura
                </Button>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {getCellContent(cell, row.id)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  );
};

export default ListGrievancesPage;
