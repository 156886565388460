import React from "react";
import Card from "../../components/Card/Card";
import ProviderForm from "./ProviderForm";

const CreateProviderPage = (
) => {


    return (
        <Card title="Registar Fornecedor">

            <ProviderForm
                mode={"CREATE"}
                url={"/sellers"}
                successMessage={"Conta criada com sucesso"}
                onSuccessNavigateTo={"../fornecedores"}
            />
        </Card>

    )
}

export default CreateProviderPage;