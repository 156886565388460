
import React from 'react';
import { Button, Tile } from 'carbon-components-react';
import { ArrowRight32 } from '@carbon/icons-react';

// TODO: temporary inline styles
const tileTitle = {
  height: '18px',
  margin: '15px 65px 24px 0',
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: 1.29,
  letterSpacing: '0.16px',
  color: '#525252'
}

const tileValue = {
  margin: '5px 35px 0 0',
  fontSize: '28px',
  lineHeight: '1.29',
  color: '#161616'
}

const tileSubTitle = {
  height: '16px',
  fontSize: '12px',
  lineHeight: 1.33,
  letterSpacing: '0.32px',
  color: '#525252'
}

const SummaryTile = ({ title, subTitle, value, onClickView }) => {
  return (
    <Tile style={{
      height: '126px',
      padding: '1px 0 16px 16px',
      border: 'solid 1px #e0e0e0',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffff'
    }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={tileTitle}>{title}</div>
        <div>
          <Button size="small" kind="ghost" onClick={onClickView}>
            View <ArrowRight32 style={{ width: '12px', height: '10px' }} />
          </Button>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={tileSubTitle}>{subTitle}</div>
          <div style={tileValue}>{value}</div>
        </div>
      </div>
    </Tile>
  );
};

export default SummaryTile;