import { Tab, Tabs } from "carbon-components-react";
import React, { useContext, useEffect, useState } from "react";
import Card from "../../components/Card/Card";
import FairsPage from "../Fair/FairPage";
import SellerProductListPage from "../Product/SellerProductList";
import ProviderFairsPage from "./ProviderFairsPage";
import ProviderUsersPage from "./ProviderUsersPage";
import { AuthContext } from "../../contexts/AuthContext";
import http from '../../http-common';

const ProviderDetailsPage = () => {
    const sellerID = localStorage.getItem("sellerID");
    const [response, setResponse] = useState();

    useEffect(() => {
        const getAllFairs = async () => {
            await retrieveCommingFair();
        };
        getAllFairs();
    }, []);

    const retrieveCommingFair = async () => {
        const responseData = await http.get("/fairs/status/" + sellerID);
        // const data = response.data.map(sensor => ({
        //   id: '' + sensor.id,
        //   description: authContext.user.role === "ROLE_SELLER" ? sensor.description : (<Link to={'../fairs/' + sensor.id}>{sensor.description}</Link>),
        //   date: sensor.date,
        //   balance: sensor.balance,
        //   province: sensor.province.description,
        //   district: sensor.district.description,
        //   status: sensor.blocked ? 'Bloqueada' : 'Operacional',
        // }));

        // setResponse(data);
        setResponse(responseData.data);
        console.log(responseData.data);
        return responseData.data;
    };
    function AlertInfo() {
        var fairsComming = [];
        console.log('response', response);
        if (
            response != null
        ) {
            fairsComming = response.fairsName;

            switch (response?.status) {
                case 0:
                    return ""
                case 1:
                    var fairsRequest = response.fairsName;

                    if (response.totalComming > response.totalRequest) {
                        return (
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">
                                        Nota: Existem Feiras que ainda não solicitou participação, garanta que solicite participação para as feiras que irá participar e não
                                        perque nenhuma feira.
                                    </h4>
                                    <h4 className="card-title">
                                        Os Pedidos Encontrados no sistema são para as Feiras de: <strong>{fairsRequest.join(',')}</strong>, se não tiver nenhuma outra feira que queira participar não se preocupe em solicitar.
                                    </h4>
                                </div>
                            </div>
                        );
                    } else {
                        return "";
                    }
                case 2:
                    return (
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">
                                    Nota: O sistema indentificou que ainda não solicitou participação para nenhuma das feiras: <strong>{fairsComming.join(',')}</strong> por decorrer
                                </h4>
                                <h4 className="card-title">
                                    Faça a solicitação, para as feiras que ira participar na aba das Feiras e não perca nenhuma feira.
                                </h4>
                            </div>
                        </div>
                    );
                default:
                    return "";
            }

        }
        return "";
    }
    return (
        <Card title="Fornecedor">
            <AlertInfo />
            <Tabs>
                <Tab id="provider_products_tab" label="Produtos">
                    {" "}
                    <SellerProductListPage />{" "}
                </Tab>
                <Tab id="provider_fairs_tab" label="Feiras">
                    {" "}
                    <FairsPage />{" "}
                </Tab>
                <Tab id="users_fairs_tab" label="Utilizadores">
                    {" "}
                    <ProviderUsersPage />{" "}
                </Tab>
            </Tabs>
        </Card>
    );
};

export default ProviderDetailsPage;
