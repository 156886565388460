import React, { useEffect, useState ,useContext} from 'react';
import http from '../../http-common';
import SummaryTile from '../../components/Tile/SummaryTile';
import { SimpleBarChart } from '@carbon/charts-react';
import '@carbon/charts/styles.css';
import { useParams,useLocation } from 'react-router-dom';
import Card from '../../components/Card/Card';
import { AuthContext } from '../../contexts/AuthContext';

const barchartOptionsSalesVolumes = {
    "title": "Vendas Por Volume (Quantidade)",
    "axes": {
        "left": { "mapsTo": "value" },
        "bottom": { "mapsTo": "group", "scaleType": "labels" }
    },
    "height": "400px"
};

const barchartOptionsSalesMoney = {
    "title": "Vendas Por Dinheiro",
    "axes": {
        "left": { "mapsTo": "value" },
        "bottom": { "mapsTo": "group", "scaleType": "labels" }
    },
    "height": "400px"
};


const SalesFairPage = () => {
    const [totalSalesMoney, setTotalSalesMoney] = useState({ group: "", value: 0 });
    const [fairId, setFairID] = useState({ id: null });
    const [bChartDataVolumes, setbChartDataVolumes] = useState({ Fertilizantes: [], Pesticidas: [], Sementes: [], Utensilios: [] });
    const [bChartDataVolumesEx, setbChartDataVolumesEx] = useState([{
        group: 'A',
        value: 200,
    }, {
        group: 'B',
        value: 120.0,
    }, {
        group: 'C',
        value: 802,
    }, {
        group: 'D',
        value: 520,
    },]);
    const params = useParams();
    const { state} = useLocation();
    const authContext = useContext(AuthContext);

    const getSalesVolumes = async (id) => {
        if(authContext.user.role==="ROLE_SELLER"){
            const response = http.get('/fair/sales/' + id +"/"+authContext.sellerID);
            setbChartDataVolumes((await response).data);
        }else{
            const response = http.get('/fair/sales/' + id);
            setbChartDataVolumes((await response).data);
        }
    
    };
    const getTotalSoldFair = async (id) => {
        if(authContext.user.role==="ROLE_SELLER"){
            const response = http.get('/fair/sales/total/' + id +"/"+authContext.sellerID);
            setTotalSalesMoney((await response).data);
        }else{
            const response = http.get('/fair/sales/total/' + id);
        setTotalSalesMoney((await response).data);
        }

        
    };
    useEffect(() => {
        setFairID({ ...fairId, id: params.id });
        getSalesVolumes(params.id);
        getTotalSoldFair(params.id);
    }, []);

    return (
        <Card 
        title={"Vendas Na Feira " + state.fairName}
        >
            <div style={{
                maxWidth: '1440px',
                minHeight: '100vh',
                padding: '16px!important',

            }}>
                <div className="bx--row">
                    <div style={{ width: '300px', marginLeft: '16px' }}>
                        <SummaryTile
                            title="Vendas Por Dinheiro"
                            subTitle="Total (MZN)"
                            value={totalSalesMoney.value} onClickView={() => { }} />
                    </div>
                </div>

                <h2>Fertilizantes</h2>
                <div className="bx--row" style={{ paddingLeft: '16px', paddingTop: '32px' }}>
                    <SimpleBarChart data={bChartDataVolumes.Fertilizantes} options={barchartOptionsSalesVolumes}></SimpleBarChart>
                </div>
                <h2>Pesticidas</h2>
                <div className="bx--row" style={{ paddingLeft: '16px', paddingTop: '32px' }}>
                    <SimpleBarChart data={bChartDataVolumes.Pesticidas} options={barchartOptionsSalesVolumes}></SimpleBarChart>
                </div>
                <h2>Sementes</h2>
                <div className="bx--row" style={{ paddingLeft: '16px', paddingTop: '32px' }}>
                    <SimpleBarChart data={bChartDataVolumes.Sementes} options={barchartOptionsSalesVolumes}></SimpleBarChart>
                </div>
                <h2>Utensilios</h2>
                <div className="bx--row" style={{ paddingLeft: '16px', paddingTop: '32px' }}>
                    <SimpleBarChart data={bChartDataVolumes.Utensilios} options={barchartOptionsSalesVolumes}></SimpleBarChart>
                </div>

            </div>
        </Card>

    );
};
export default SalesFairPage;