import React from "react";
import Card from "../../components/Card/Card";
import ProviderForm from "./ProviderForm";

const SignupProviderPage = () => {

    return (
        <div className="bx--row">
            <div className="bx--col-lg-6 content"></div>

            <div className="bx--col-sm-12 bx--col-lg-6">
                <Card title="Regista-te">
                    <ProviderForm
                        mode={"SIGNUP"}
                        url={"/signup/sellers"}
                        onSuccessNavigateTo={"/"}
                        successMessage="Conta Criada com sucesso"
                    />
                </Card>
            </div>
        </div>

    );

}

export default SignupProviderPage;