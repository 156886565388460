import Card from "../../components/Card/Card";
import FairForm from "./FairForm";

const CreateFairPage=()=>{

    return ( 
        <Card
        title="Adicionar Feira"
        > 
        <FairForm 
        mode={"CREATE"}
        url={"fairs/"}
        sucessMessage={"Feira criada com sucesso"}
        onSuccessNavigateTo={"../inicio"}
           
        />
        </Card>
    );
};
export default CreateFairPage;
