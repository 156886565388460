import { DataView24, Edit24 } from "@carbon/icons-react";
import { Search32 } from "@carbon/icons-react";
import { Add16 } from "@carbon/icons-react";
import { Form } from "carbon-components-react";
import {
  Row,
  Column,
  TextInput,
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from "carbon-components-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card/Card";
import TablePagination from "../../components/Pagination/Pagination";
import http from "../../http-common";

const tableHeaders = [
  {
    header: "Nome",
    key: "firstName",
  },
  {
    header: "Apelido",
    key: "lastName",
  },
  {
    header: "Número de Telefone",
    key: "phoneNumber1",
  },
  {
    header: "Sexo",
    key: "gender",
  },
  {
    header: "Negócio Existente",
    key: "hasBusinessCurrently",
  },
  {
    header: "Estado",
    key: "currentStageDesc",
  },
  {
    header: "Eligibilidade",
    key: "currentEligibilityStatusDesc",
  },
  {
    header: " ",
    key: "actions",
  },
];

const LinkList = ({ url, url2, isOpen }) => (
  <ul style={{ display: "flex" }}>
    <li>
      <Link to={url} title="Editar">
        <Edit24 />
      </Link>
    </li>
    &nbsp;&nbsp;&nbsp;
    <li>
      <Link to={url2} title="Visualizar">
        <DataView24 />
      </Link>
    </li>
  </ul>
);

const getCellContent = (cell, id) => {
  if (cell.info.header === "actions") {
    return (
      <LinkList
        url={"../editar-candidatura/" + id}
        url2={"../visualizar-candidatura/" + id}
      />
    );
  }
  return cell.value;
};

const ListApplicationsPage = () => {
  const [apps, setApps] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const featchData = async () => {
    try {
      const response = await http.get(
        "/applications/search/filter?size=" + size
      );
      getApplicationData(response);
    } catch (error) {}
  };

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1;
      const response = await http.get(
        "/applications/search/filter?page=" + pag + "&size=" + pageSize
      );
      getApplicationData(response);
    } catch (error) {}
  };

  const onSearch = async (data) => {
    try {
      console.log(data);
      const response = await http.get(
        `/applications/search/filter?competitor.firstName=${data.firstName}&competitor.lastName=${data.lastName}&competitor.phoneNumber1=${data.phoneNumber1}`
      );
      getApplicationData(response);
    } catch (error) {}
  };

  const getApplicationData = (response) => {
    const data = response.data.content.map((app) => ({
      id: app.uuid,
      nuit: app.competitor.nuit,
      firstName: app.competitor.firstName,
      lastName: app.competitor.lastName,
      phoneNumber1: app.competitor.phoneNumber1,
      gender: app.competitor.gender.code,
      hasBusinessCurrently: app.hasBusinessCurrently ? "Sim" : "Não",
      currentStageDesc: app.currentStage.description,
      currentEligibilityStatusDesc: app.currentEligibilityStatus.description,
    }));
    setApps(data);
    setCount(response.data.totalElements);
  };

  useEffect(() => {
    featchData();
  }, []);

  return (
    <Card title="Candidaturas">
      <div className="mb-2 form-buttons">
        <p></p>
        <Button
          kind="ghost"
          type="button"
          onClick={() => navigate("../registar-candidatura")}
          renderIcon={Add16}
        >
          Registar Candidatura
        </Button>
      </div>

      <DataTable rows={apps} headers={tableHeaders} isSortable useZebraStyles>
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <TableToolbar aria-label="data table toolbar">
              <TableToolbarContent>
                {/* <TableToolbarSearch
                  placeholder="Digite o número de telefone para pesquisar"
                  onChange={(e) => search(e.target.value)}
                /> */}
                <Form
                  className="data-table-search-form"
                  onSubmit={handleSubmit(onSearch)}
                >
                  <Row>
                    <Column sm={6} md={4} lg={4}>
                      <TextInput
                        id="firstName"
                        labelText=""
                        placeholder="Nome"
                        {...register("firstName")}
                      />
                    </Column>
                    <Column sm={6} md={4} lg={4}>
                      <TextInput
                        id="lastName"
                        labelText=""
                        placeholder="Apelido"
                        {...register("lastName")}
                      />
                    </Column>
                    <Column sm={6} md={3} lg={3}>
                      <TextInput
                        id="phoneNumber1"
                        labelText=""
                        placeholder="Telefone"
                        {...register("phoneNumber1")}
                      />
                    </Column>
                  </Row>
                  <Button type="submit" renderIcon={Search32}>
                    Pesquisar
                  </Button>
                </Form>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {getCellContent(cell, row.id)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  );
};

export default ListApplicationsPage;
