import React from "react";
import Card from "../../components/Card/Card";

const ApplicationValidationPage = () => {
  return (
    <Card title="Validação de Candidaturas">
      <p>Em Desenvolvimento</p>
    </Card>
  );
};

export default ApplicationValidationPage;
