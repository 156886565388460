import {
    Button, Link, OverflowMenu, OverflowMenuItem, Tab, Tabs, FileUploader, ModalWrapper, Loading,
} from 'carbon-components-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import http from '../../http-common';
import * as XLSX from 'xlsx';
import ObjectTableList from '../../components/object-table-list/object-table-list';
import { toast } from "react-toastify";
import Card from "../../components/Card/Card";

const FairDetailsPage = () => {
    const { fairId } = useParams();
    const [fair, setFair] = useState();
    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [warning, setWarning] = useState(null);
    const [provinces, setProvinces] = useState([]);
    const [showLoading, setShowLoading] = useState(false);

    const getFair = async () => {
        const response = await http.get('/fairs/' + fairId);

        setFair(response.data);
    }

    const fetchProvinces = async () => {
        try {
            const response = await http.get('/provinces');
            let items = response.data.map(e => {
                return { id: e.id, text: e.description, children: e.children };
            })

            setProvinces(items);

            return { success: true, data: items };
        } catch (error) {
            return { success: false };
        }
    };



    useEffect(() => {
        const abortController = new AbortController();
        getFair();
        return () => { abortController.abort(); }
    }, []);

    useEffect(() => {
        (async () => {
            let response = await fetchProvinces();
            if (response.success) {

                setProvinces(response.data);

            }
        })()
    }, []);

    const actions = (
        <OverflowMenu
            flipped
        >
            {fair && <OverflowMenuItem
                itemText={fair.blocked ? 'Desbloquear' : 'Bloquear'}
                onClick={() => blockOrUnblockFair()}
            />}
        </OverflowMenu>);

    const blockOrUnblockFair = async () => {
        fair.blocked = !fair.blocked;
        let response = await http.post('/fairs/update', fair);
        setFair(response.data);
    }

    const sellersTableHeaders = [
        { key: 'code', header: 'Codigo' },
        { key: 'firstName', header: 'Nome legal' },
        { key: 'surName', header: 'Nome comercial' },
        { key: 'documentCode', header: 'NUIT / NUEL' },
        { key: 'actions', header: 'Acções' },
    ];


    const importSellers = event => {
        const file = event.target.files[0];
        readExcel(file);
        // persistSellers();
        // toast.success("Finalizado");

    };
    const importFarmers = event => {
        const file = event.target.files[0];
        readFarmerExcel(file);

        //     persistFarmers();
        // toast.success("Finalizado");


    };
    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                const dataToSent = [];

                data.forEach(d => {
                    let province = provinces.find(p => p.text == d.province);
                    d['provinceId'] = province.id;
                    delete d.province;
                });

                resolve(data);
                return true;
            };

            fileReader.onerror = (error) => {
                reject(error);
                toast.error(error);
                return false;
            };
        });

        promise.then((d) => {
            setItems(d);
        });
    };

    const persistSellers = () => {
        if (items.length > 0) {

            http.post('/sellers/import?fairId=' + fairId, items)
                .then(response => {
                    toast.success("Importado");

                })
                .catch(error => {
                    toast.error(error.response.data.message);

                });
        }
        else {
            toast.error("Nao Importadoo");

        }

    };

    const readFarmerExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
                toast.error(error);

            };
        });

        promise.then((d) => {
            setItems(d);
        });
    };

    const persistFarmers = () => {
        setShowLoading(true);
        if (items.length > 0) {
            http.get('/fairs').then(response => {
                if (hasActiveFair(response.data)) {
                    http.post('/farmers/import?fairId=' + fairId, items)
                        .then(response => {
                            setShowLoading(false);

                            toast.success("Importado");

                        })
                        .catch(error => {
                            toast.error(error);
                        });
                } else {
                    toast.error('Não existe nenhuma feira activa no sistema, por favor active uma para importar');

                }


            });

        }
        else {
            toast.error('Não Importado');
        }

    };
    const farmersHeaders = [
        { key: 'code', header: 'Codigo' },
        { key: 'firstName', header: 'Nome' },
        { key: 'surName', header: 'Sobrenome' },
        { key: 'documentCode', header: 'Documento' },
        { key: 'community', header: 'Comunidade' },
        { key: 'actions', header: 'Acções' },
    ];

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };



    const hasActiveFair = (fairsList) => {
        if (fairsList.length == 0) { return false };
        const activeFairs = fairsList.filter(f => f.active);
        return activeFairs.length > 0;
    };
    return (
        <Card
            title={"Feira"}
        >
            <div style={{ marginTop: '3rem', backgroundColor: '#fff' }}>
                <Tabs>
                    <Tab id="sellers-tab" label="Vendedores">
                        <ObjectTableList
                            endpoint={'sellerspaginated?fairId=' + fairId}
                            headers={sellersTableHeaders}
                            dataObjectName={'sellers'}

                            fairId={fairId} />
                    </Tab>

                    <Tab id="farmers-tab" label="Agricultores">
                        <ObjectTableList
                            endpoint={'farmersx?fairId=' + fairId}
                            headers={farmersHeaders}
                            dataObjectName={'farmers'}
                            addButton={<>   <ModalWrapper
                                buttonTriggerText="Importar Agricultores"
                                modalHeading="Importacao de Agricultores"
                                modalLabel=""
                                primaryButtonText="Sincronizar com o Servidor"
                                secondaryButtonText={null}
                                handleSubmit={persistFarmers}
                            >
                                <p> Primeiro Selecione o ficheiro que sera importado, de seguida selecione sincronizar com o servidor e aguarde pela mensagem de confirmação</p>
                                {" "}
                                <div style={{ textAlign: 'end' }}>
                                    <FileUploader
                                        accept={[
                                            '.xlsx',

                                        ]}
                                        buttonKind="secondary"
                                        buttonLabel="Selecionar Ficheiro"
                                        filenameStatus="edit"
                                        iconDescription="Clear file"
                                        labelDescription=""
                                        labelTitle=""
                                        onChange={importFarmers}
                                    />
                                    {
                                        showLoading == true ?
                                            <Loading
                                                description="Active loading indicator" withOverlay={false}
                                                small /> : ""
                                    }
                                </div>
                            </ModalWrapper>     </>} />
                    </Tab>
                </Tabs>
            </div>
        </Card>
    );
};

export default FairDetailsPage;