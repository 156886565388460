import React, { useState } from "react";
import {
  Row,
  Column,
  FormGroup,
  TextArea,
  RadioButtonGroup,
  RadioButton,
  TextInput,
} from "carbon-components-react";

const ExecutiveSummaryForm = ({
  disabled,
  register,
  errors,
  hasBusinessCurrently,
  setHasBusinessCurrently,
}) => {
  const handleHasBusinessCurrently = (e) => {
    setHasBusinessCurrently(e);
  };
  return (
    <FormGroup
      legendText="SECÇÃO B. SUMÁRIO EXECUTIVO"
      className="section-header"
    >
      <fieldset className="bx--fieldset">
        <Row>
          <Column xsm={12} sm={12} md={12} lg={12} className="required">
            <TextArea
              id="competitorData"
              labelText="B1. Dados do concorrente/sócios do negócio"
              rows={15}
              enableCounter
              maxCount={4000}
              disabled={disabled}
              helperText="[Indique o nome dos/as proprietários/as do negócio e descreva de forma sumária, destacando a(s) suas(s) participações no negócio, as qualificações académicas, outras qualificações vocacionais/profissionais, e experiência de trabalho do/a candidato/a e do(s) seu(s) sócios.]"
              {...register("competitorData")}
            />
            <p className="field-error">{errors.competitorData?.message}</p>
          </Column>
        </Row>
      </fieldset>
      <fieldset className="bx--fieldset required" style={{ marginBottom: "0" }}>
        <legend className="bx--label">B2. Tem um Negócio Actualmente?</legend>
        <Row>
          <Column>
            <RadioButtonGroup
              name="hasBusinessCurrently"
              disabled={disabled}
              onChange={handleHasBusinessCurrently}
              valueSelected={hasBusinessCurrently}
            >
              <RadioButton
                labelText="Sim. Preencha a secção C e a secção D"
                value="Sim"
                id="section-c"
                {...register("hasBusinessCurrently")}
              />
              <RadioButton
                labelText="Não. Preencha a secção D"
                value="Não"
                id="section-d"
                {...register("hasBusinessCurrently")}
              />
            </RadioButtonGroup>
            <p className="field-error">
              {errors.hasBusinessCurrently?.message}
            </p>
          </Column>
        </Row>
      </fieldset>
    </FormGroup>
  );
};

ExecutiveSummaryForm.defaultProps = {
  disabled: false,
};
export default ExecutiveSummaryForm;
