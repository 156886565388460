import { DataView24, Edit24, SubtractAlt24 } from "@carbon/icons-react";
import { Search32 } from "@carbon/icons-react";
import { Add16 } from "@carbon/icons-react";
import { Form } from "carbon-components-react";
import {
    Row,
    Column,
    TextInput,
    Button,
    DataTable,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableToolbar,
    TableToolbarContent,
} from "carbon-components-react";
import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card/Card";
import TablePagination from "../../components/Pagination/Pagination";
import http from "../../http-common";
import { AuthContext } from "../../contexts/AuthContext";
import CustomTable from "../../components/custom-table/custom-table";
import { toast } from "react-toastify";

const tableHeaders = [
    { key: 'category', header: 'Categoria' },
    { key: 'product', header: 'Produto' },
    { key: 'measurementUnit', header: 'Unidade de Medida' },
    { key: 'quantity', header: 'Quantidade Unitaria' },
    { key: 'price', header: 'Preço' },
    { key: 'actions', header: 'Acções' },
];


const updateStatusSellerProduct = async (url) => {
    try {
        toast.success("Inativando Produto ..... ");
        const response = await http.post(url);
        if (response.status == 200) {
            toast.success("Inativado Com Sucesso .....");

        }
    } catch (error) {
        toast.error(error.response?.data.message);

    }


};

const LinkList = ({ url, url2, isOpen }) => (
    <ul style={{ display: "flex" }}>
        <li>
            <Link to={url} title="Editar">
                <Edit24 />
            </Link>
        </li>
        &nbsp;&nbsp;&nbsp;
        <li>
            <SubtractAlt24 onClick={() => updateStatusSellerProduct(url2)} />
        </li>
    </ul>
);

const getCellContent = (id) => {
    // if (cell.info.header === "actions") {
    return (
        <LinkList
            url={"../edit-product/" + id}
            url2={"sellerProduct/" + id + "/1"}
        />
    );
    // }
    // return cell.value;
};
const SellerProductListPage = () => {
    const [count, setCount] = useState(0);
    const [size, setSize] = useState(20);
    const [rowsSellerProducts, setRowsSellerProducts] = useState([]);
    const sellerID = localStorage.getItem("sellerID");

    const navigate = useNavigate();

    const authContext =
        useContext(AuthContext);
    const sellerProductRows = rowsSellerProducts.map(p => {

        return {
            id: p.id,
            category: p.product.category.description,
            product: p.productDescription,
            measurementUnit: p.measurementUnit,
            quantity: p.quantity,
            price: p.price,
            actions: getCellContent(p.id),
        };
    });

    const retrieveSellerProducts = async (id) => {
        const response = await http.get('/sellerproductx/' + id);

        setRowsSellerProducts(response.data);

        return response.data;
    };

    const addSellerProduct = () => {
        navigate('../create-product');
    }

    const addProductButton = (

        <div style={{ textAlign: 'end' }}>
            <Button onClick={addSellerProduct} >Adicionar Produto</Button>
        </div>
    );

    // const onSearch = async (data) => {
    //     try {
    //       const response = await http.get(
    //         ``
    //       );
    //       setRowsSellerProducts(response.data);
    //     } catch (error) {}
    //   };
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        retrieveSellerProducts(sellerID);
    }, []);

    return (
        <div>
            {/* <ProductDetailsBanner />
            <div style={{ gridColumn: 'span 4 / auto' }}>

            </div> */}

            <div style={{ marginTop: '3rem', backgroundColor: '#fff' }}>
                <CustomTable
                    tableTitle="PRODUTOS"
                    tableHeaders={tableHeaders}
                    tableRows={sellerProductRows}
                    addButton={addProductButton}
                />
            </div>
            {
                rowsSellerProducts.length > 0 ? "" : <p>Adicione Produtos, que deseja comercializar</p>
            }
        </div>
    );
};
export default SellerProductListPage;