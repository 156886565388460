import {
    Button, Link, OverflowMenu, OverflowMenuItem, Tab, Tabs, FileUploader, ModalWrapper,
    Pagination,Form, Row,
    Column,
    TextInput,
} from 'carbon-components-react';
import React, { useEffect, useState, useContext } from 'react';
import http from '../../http-common';
import Card from '../../components/Card/Card';
import CustomTable from '../../components/custom-table/custom-table';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Search32,} from "@carbon/icons-react";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../contexts/AuthContext";


const ApprovalPage = ({ status }) => {
    const navigate = useNavigate();
    const approvalsHeaders = [
        { key: 'seller', header: 'Vendedor' },
        { key: 'status', header: 'Estado' },
        { key: 'typeId', header: 'Tipo' },
        { key: 'product', header: 'Nome do Producto' },
        { key: 'category', header: 'Categoria' },
        { key: 'fair', header: 'Feira' },
        { key: 'actions', header: 'Acções' }

    ];
    const approvalsJoinMediaHeaders = [
        { key: 'seller', header: 'Vendedor' },
        { key: 'status', header: 'Estado' },
        { key: 'typeId', header: 'Tipo' },
        { key: 'description', header: 'Descricao Documento' },
        { key: 'type', header: 'Tipo de Documento' },
        { key: 'productDescription', header: 'Produto Do Vendedor' },
        { key: 'actions', header: 'Acções' },
    ];
    const [tableRows, setTableRows] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPageSize, setCurrentPageSize] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    
    const authContext =
        useContext(AuthContext);
    function acceptApproval(approval) {
        return async () => {
            try {
                const response = await http.post("/approval/approve/" + approval.id+"/"+authContext.user.username);
                if (response.status === 200) {
                    toast.success("Aprovacao Com Sucesso");
                } else {
                    toast.success("Ocorreu algum Erro!");
                }

            } catch (error) {
                toast.error(error.response?.data.message);
            }
        };
    };

    function rejectApproval(approval) {
        return async () => {
            try {
                const response = await http.post("/approval/reject/" + approval.id+"/"+authContext.user.username);
                if (response.status === 200) {
                    toast.success("Rejeitado Com Sucesso");
                } else {
                    toast.success("Ocorreu algum  Erro!");
                }

            } catch (error) {
                toast.error(error.response?.data.message);
            }
        };
    };
    const tableActions = (approval) => {
        return (<OverflowMenu
            flipped>
            <OverflowMenuItem
                key='approve'
                itemText="Aprovar"
                onClick={acceptApproval(approval)}
            />
            <OverflowMenuItem
                key='reject'
                itemText="Rejeitar"
                onClick={rejectApproval(approval)}
            />
            <OverflowMenuItem
                key='details'
                itemText="Detalhes"
                onClick={() => navigate("../approval-details", { state: { approvalData: approval } })}
            />
        </OverflowMenu>);
    };
    const approvalStatus = (status) => {
        switch (status) {
            case 0:
                return 'Aceite';
            case 1:
                return 'Rejeitado';
            case 2:
                return 'Pedente de Aprovação';
            case 3:
                return 'Por Submeter'
            default:
                return '';
        }
    };

    const approvalStatusTitle = (status) => {
        switch (status) {
            case 0:
                return 'Aceitas';
            case 1:
                return 'Rejeitadas';
            case 2:
                return 'Pedentes';
            case 3:
                return 'Por Submeter'
            default:
                return '';
        }
    };
    const getData = async (page, pageSize) => {
        let startOffset = page == 0 ? 0 : page - 1;
        const response = await http.get('/approval/approvalx?status=' + status + '&page=' + startOffset);
        const data = response.data;
        const rows = response.data['approvals'].map(approval => ({
            id: approval.id,
            status: approvalStatus(approval.status),
            seller: approval.seller?.firstName,
            typeId: approval.typeId?.description,
            product: approval.product?.description,
            category: approval.product?.category?.description,
            fair: approval.fair?.description,
            actions: tableActions(approval),
        }));
        setCurrentPageNumber(page)
        setCurrentPageSize(data.approvals.length);
        setTotalItems(data.totalItems);
        setTableRows(rows);
    };

    const onSearch = async (terms) => {
        try {
            const response = await http.get(`/approval/approvalx/search?status=${status}&firstName=${terms.firstName}&documentCode=${terms.documentCode}`);
            const data = response.data;
            const rows = response.data['approvals'].map(approval => ({
                id: approval.id,
                status: approvalStatus(approval.status),
                seller: approval.seller?.firstName,
                typeId: approval.typeId?.description,
                product: approval.product?.description,
                category: approval.product?.category?.description,
                fair: approval.fair?.description,
                actions: tableActions(approval),
            }));
            setCurrentPageSize(data.approvals.length);
            setTotalItems(data.totalItems);
            setTableRows(rows);
        } catch (error) { }
    };
    useEffect(() => {
        const abortController = new AbortController();
        getData(0, 5);
        return () => { abortController.abort(); }
    }, []);
    return (
        <div style={{ marginTop: '3rem', backgroundColor: '#fff' }}>
            <Card
                title={"Aprovações " + approvalStatusTitle(status)}
            >
                <CustomTable
                    tableHeaders={approvalsHeaders}
                    tableRows={tableRows}
                    addButton={
                        <>
                          <Form
                                    className="data-table-search-form"
                                    onSubmit={handleSubmit(onSearch)}
                                >
                                    <Row>
                                        <Column sm={6} md={6} lg={5}>
                                            <TextInput
                                                id="firstName"
                                                labelText=""
                                                placeholder="Nome Legal"
                                                {...register("firstName")}
                                            />
                                        </Column>

                                        <Column sm={6} md={6} lg={5}>
                                            <TextInput
                                                id="documentCode"
                                                labelText=""
                                                placeholder="Nuit"
                                                {...register("documentCode")}
                                            />
                                        </Column>
                                    </Row>
                                    <Button type="submit" renderIcon={Search32}>
                                        Pesquisar
                                    </Button>

                                </Form>
                        </>} />
                <Pagination
                    totalItems={totalItems}
                    backwardText="Previous page"
                    forwardText="Next page"
                    pageSize={currentPageSize}
                    pageSizes={[10, 20, 30, 40, 50]}
                    itemsPerPageText="Items per page"
                    onChange={({ page, pageSize }) => {
                        getData(page, pageSize);
                    }}
                    page={currentPageNumber}
                />

            </Card>

        </div>

    );
};

export default ApprovalPage;