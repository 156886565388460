import { DataView24, Edit24 } from "@carbon/icons-react";
import { Add16 } from "@carbon/icons-react";
import {
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} from "carbon-components-react";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card/Card";
import TablePagination from "../../components/Pagination/Pagination";
import { AuthContext } from "../../contexts/AuthContext";
import http from "../../http-common";

const tableHeaders = [
  // {
  //   header: "NUR",
  //   key: "nur",
  // },
  {
    header: "Negócio Existente",
    key: "hasBusinessCurrently",
  },
  {
    header: "Estado",
    key: "currentStageDescription",
  },
  {
    header: "Pontuação total do actual juri",
    key: "totalScore",
  },
  {
    header: " ",
    key: "actions",
  },
];

const LinkList = ({ url }) => (
  <ul style={{ display: "flex" }}>
    <li>
      <Link to={url} title="Consultar">
        <DataView24 />
      </Link>
    </li>
  </ul>
);

const getCellContent = (cell, id) => {
  if (cell.info.header === "hasBusinessCurrently") {
    return cell.value ? "Sim" : "Não";
  }
  if (cell.info.header === "actions") {
    return <LinkList url={"../visualizar-candidatura/" + id} />;
  }
  return cell.value;
};

const ApplicationEvaluationPage = () => {
  const { user, isJury } = useContext(AuthContext);
  const [apps, setApps] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);

  const navigate = useNavigate();

  const featchData = async () => {
    try {
      const response = await http.get(
        `/applications/jury-applications/filter?username=${user.username}&size=${size}`
      );
      // getApplicationData(response);
      setApps(response.data.content);
      setCount(response.data.totalElements);
    } catch (error) {}
  };

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1;
      const response = await http.get(
        `/applications/jury-applications/filter?username=${user.username}&page=${pag}&size=${pageSize}`
      );
      setApps(response.data.content);
      setCount(response.data.totalElements);
    } catch (error) {}
  };

  const search = async (phoneNumber1) => {
    try {
      const response = await http.get(
        "/applications/search/filter?competitor.phoneNumber1=" + phoneNumber1
      );
      setApps(response.data.content);
      // getApplicationData(response);
    } catch (error) {}
  };

  const getApplicationData = (response) => {
    const data = response.data.content.map((item) => ({
      id: item.application.uuid,
      // nur: "NUR" + item.application.competitor.phoneNumber1,
      hasBusinessCurrently: item.application.hasBusinessCurrently
        ? "Sim"
        : "Não",
      currentStageDesc: item.application.currentStage.description,
      currentEligibilityStatusDesc:
        item.application.currentEligibilityStatus.description,
    }));
    setApps(data);
    setCount(response.data.totalElements);
  };

  useEffect(() => {
    featchData();
  }, []);

  return (
    <Card title="Avaliação de Candidaturas">
      <DataTable rows={apps} headers={tableHeaders} isSortable useZebraStyles>
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {getCellContent(cell, row.id)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  );
};

export default ApplicationEvaluationPage;
