import React, { useContext, useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import logo from "../assets/images/mainlogo.png";
import whatsappLogo from "../assets/images/whatsappIcon.png";
import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderMenuButton,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  SideNavMenu,
  SideNav,
  SideNavItems,
} from "carbon-components-react/lib/components/UIShell";
import {
  AppSwitcher20,
  Home16,
  UserAvatarFilledAlt32,
  Logout16,
  Email16,
  ApplicationMobile20,
} from "@carbon/icons-react";
import { ToastContainer } from "react-toastify";
import menu from "../services/NavigationService";
import { AuthContext } from "../contexts/AuthContext";
import { Navigate } from "react-router-dom";

const Fade16 = () => (
  <svg
    width="16"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    aria-hidden="true"
  >
    <path d="M8.24 25.14L7 26.67a14 14 0 0 0 4.18 2.44l.68-1.88a12 12 0 0 1-3.62-2.09zm-4.05-7.07l-2 .35A13.89 13.89 0 0 0 3.86 23l1.73-1a11.9 11.9 0 0 1-1.4-3.93zm7.63-13.31l-.68-1.88A14 14 0 0 0 7 5.33l1.24 1.53a12 12 0 0 1 3.58-2.1zM5.59 10L3.86 9a13.89 13.89 0 0 0-1.64 4.54l2 .35A11.9 11.9 0 0 1 5.59 10zM16 2v2a12 12 0 0 1 0 24v2a14 14 0 0 0 0-28z" />
  </svg>
);

const AdminLayout = () => {
  const { authenticated, user, isCompetitor, loading, logout, } =
    useContext(AuthContext);

  const nav = menu(user?.role);

  useEffect(() => {
    document.body.classList.remove("public-page");
    document.body.classList.add("admin-page");
  }, []);

  if (loading) {
    return <div className="loading">Carregando...</div>;
  }

  if (!authenticated) {
    return <Navigate to="/" />;
  }
  return (
    <div className="container">
      <HeaderContainer
        render={({ isSideNavExpanded, onClickSideNavExpand }) => (
          <>
            <Header aria-label="IBM Platform Name">
              <SkipToContent />
              <HeaderMenuButton
                aria-label="Open menu"
                onClick={onClickSideNavExpand}
                isActive={isSideNavExpanded}
              />
              <HeaderName href="#" prefix="">
                <img src={logo} alt="Digital Fairs iDE" />
              </HeaderName>
              <HeaderGlobalBar></HeaderGlobalBar>
              <SideNav
                aria-label="Side navigation"
                expanded={isSideNavExpanded}
              >
                <div className="user-details">
                  <UserAvatarFilledAlt32 />
                  {user.firstName + " " + user.lastName}
                </div>
                <SideNavItems>
                  <li className="bx--side-nav__item">
                    <Link
                      to={"/admin/inicio"}
                      className="bx--side-nav__link single-nav-item"
                    >
                      <div className="bx--side-nav__icon bx--side-nav__icon--small">
                        <Home16 />
                      </div>
                      Início
                    </Link>
                  </li>
                  {nav.map((menu) => (
                    <SideNavMenu
                      renderIcon={Fade16}
                      title={menu.label}
                      key={menu.id}
                    >
                      {menu.items.map((item) => (
                        <li className="bx--side-nav__menu-item" key={item.id}>
                          <Link
                            to={item.link}
                            key={item.id}
                            className="bx--side-nav__link single-nav-item"
                          >
                            {item.label}
                          </Link>
                        </li>
                      ))}
                    </SideNavMenu>
                  ))}
                  <li className="bx--side-nav__item">
                    <Link
                      onClick={logout}
                      to={""}
                      className="bx--side-nav__link single-nav-item"
                    >
                      <div className="bx--side-nav__icon bx--side-nav__icon--small">
                        <Logout16 />
                      </div>
                      Sair
                    </Link>
                  </li>
                  
                </SideNavItems>
                <SideNavItems>
                <div className="user-details"> 
                  <p>Esta a ter alguma didiculdade?</p>
                  Contacte-nos através
                  </div>
                  <li className="bx--side-nav__item">
                    <Link
                      to={"#"}
                      className="bx--side-nav__link single-nav-item"
                    >
                      <div className="bx--side-nav__icon bx--side-nav__icon--small">
                      <Email16 />
                      </div>
                      <p>{"   "}feiras@ideglobal.org</p>
                    </Link>
                  </li>
                  <li className="bx--side-nav__item">
                    <Link
                      to={"#"}
                      className="bx--side-nav__link single-nav-item"
                    >
                      <div className="bx--side-nav__icon bx--side-nav__icon--small">
                      <img src={whatsappLogo} width="17px" height="17px" alt="contactsHelper"/>
                      </div>
                      <p>+258842624615</p>
                    </Link>
                  </li>
                </SideNavItems>
              </SideNav>
            </Header>
            <div
              className="bx--grid"
              style={{ paddingTop: "4rem", paddingBottom: "1.2rem" }}
            >
              <div className="main">
                <div className="bx--col-lg-14 bx--offset-lg-2">
                  <ToastContainer
                    theme="colored"
                    position="top-right"
                    autoClose={8000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                  <Outlet />
                </div>
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
};

export default AdminLayout;
