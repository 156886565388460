import React from "react";
import Card from "../../components/Card/Card";
import ApprovalPage from "./ApprovalPage";

const PendingApprovals =()=>{ 
    return ( 
     
        <ApprovalPage 
        status={2}
        />
   
    );
};

export default PendingApprovals;