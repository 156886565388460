import React from "react";
import { Button } from "carbon-components-react";
import { RuleCancelled24 } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";

const CancelButton = ({ label, navigateTo, icon }) => {
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate(navigateTo && navigateTo.trim() ? navigateTo : -1);
  };
  return (
    <Button
      renderIcon={icon}
      iconDescription={label}
      kind="secondary"
      onClick={handleCancel}
    >
      {label}
    </Button>
  );
};

CancelButton.defaultProps = {
  label: "Cancelar",
  icon: RuleCancelled24,
};

export default CancelButton;
