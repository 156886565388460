import React, { useState, useEffect } from "react";
import Card from "../../components/Card/Card";
import { Form } from "carbon-components-react";
import {
    Row,
    Column,
    TextInput,
    Button,
    DataTable,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableToolbar,
    TableToolbarContent,
    OverflowMenuItem,
    OverflowMenu,
} from "carbon-components-react";
import { Link, useNavigate } from "react-router-dom";
import { Add16, Search32, DataView24, Edit24, TrashCan24 } from "@carbon/icons-react";
import CustomTable from "../../components/custom-table/custom-table";
import http from "../../http-common";
import TablePagination from "../../components/Pagination/Pagination";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useGet from "../../hooks/useGet";

const tableHeaders = [
    {
        header: "Código",
        key: "code",
    },
    {
        header: "Nome Legal",
        key: "firstName",
    },
    {
        header: "Nome Comercial",
        key: "surName",
    },
    {
        header: "Nuit",
        key: "documentCode",
    },
    {
        header: "Contacto",
        key: "contact",
    },
    {
        header: "Estado",
        key: "status",
    },
    {
        header: "Operações",
        key: "actions",
    },
];

const LinkList = ({ url, url2 }) => (
    <ul style={{ display: "flex" }}>
        <li>
            <Link to={url} title="Editar">
                <Edit24 />
            </Link>
        </li>
        &nbsp;&nbsp;&nbsp;
        {/* <li>
            <Link to={url2} title="Inactivar" className="emprega-orange">
                <TrashCan24 />
            </Link>
        </li> */}
    </ul>
);


const ProviderPage = () => {

    const navigate = useNavigate();

    const [provider, setProvider] = useState([]);
    const [count, setCount] = useState(0);
    const [size, setSize] = useState(20);
    const { data: approvals } = useGet("/sellers/registration/approval");

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const getApprovals = (id) => {
        const app = approvals.find(l => l.id == id);

        if (app !== undefined) {

            switch (app.status) {
                case 0:
                    return "Aprovado";
                case 1:
                    return "Recusado";
                case 2:
                    return "Pendente de Aprovação";

            }
        } else {
            return "Por Submeter";
        }
    };

    const featchData = async () => {
        try {
            const response = await http.get('/sellers/search/filter?size=' + size);
            getProviderData(response);
        } catch (error) { }
    };

    const getProviderData = (response) => {
        const data = response.data.content.map(sensor => ({
            id: sensor.id,
            code: sensor.code,
            firstName: sensor.firstName,
            surName: sensor.surName,
            documentCode: sensor.documentCode,
            contact: sensor.contact,
            status: getApprovals(sensor.id),
            actions: tableActions(sensor),
        }));
        setProvider(data);
        setCount(response.data.totalElements);
    }

    const onPageChange = async ({ page, pageSize }) => {
        try {
            var pag = page - 1;
            const response = await http.get('/sellers/search/filter?page=' + pag + '&size=' + pageSize);
            getProviderData(response);
        } catch (error) { }
    };

    const onSearch = async (data) => {
        try {
            const response = await http.get(`/sellers/search/filter?firstName=${data.firstName}&documentCode=${data.documentCode}`);
            getProviderData(response);
        } catch (error) { }
    };


    useEffect(() => {
        featchData();
    }, []);

    const approve = async (seller) => {
        try {
            const response = await http.post("/approval/seller/" + seller.id);
            if (response.status === 200) {
                toast.success("Aprovacao Com Sucesso");
            } else {
                toast.success("Ocorreu algum Erro!");
            }

        } catch (error) {
            toast.error(error.response?.data.message);
        }}
 
    const tableActions = (seller) => {

        return (<OverflowMenu
            flipped
        >

            <OverflowMenuItem
                key="optionOne"
                itemText="Editar"
                onClick={() => navigate("../actualizar-fornecedor/" + seller.id)}
            />


            <OverflowMenuItem
                itemText={'Aprovar'}
                onClick={() => approve(seller)}
            />
        </OverflowMenu>
        );
    };

    return (
        <Card title="Fornecedores">

            <div className="mb-2 form-buttons">
                <p></p>
                <Button
                    kind="ghost"
                    type="button"
                    onClick={() => navigate("../registar-fornecedor")}
                    renderIcon={Add16}
                >
                    Registar Fornecedor
                </Button>
            </div>

            <DataTable rows={provider} headers={tableHeaders} isSortable useZebraStyles>
                {({ rows, headers, getHeaderProps, getTableProps }) => (
                    <TableContainer>
                        <TableToolbar aria-label="data table toolbar">
                            <TableToolbarContent>
                                <Form
                                    className="data-table-search-form"
                                    onSubmit={handleSubmit(onSearch)}
                                >
                                    <Row>
                                        <Column sm={6} md={6} lg={5}>
                                            <TextInput
                                                id="firstName"
                                                labelText=""
                                                placeholder="Nome Legal"
                                                {...register("firstName")}
                                            />
                                        </Column>

                                        <Column sm={6} md={6} lg={5}>
                                            <TextInput
                                                id="documentCode"
                                                labelText=""
                                                placeholder="Nuit"
                                                {...register("documentCode")}
                                            />
                                        </Column>
                                    </Row>
                                    <Button type="submit" renderIcon={Search32}>
                                        Pesquisar
                                    </Button>

                                </Form>
                            </TableToolbarContent>
                        </TableToolbar>
                        <Table {...getTableProps()}>
                            <TableHead>
                                <TableRow>
                                    {headers.map((header) => (
                                        <TableHeader {...getHeaderProps({ header })}>
                                            {header.header}
                                        </TableHeader>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.id}>
                                        {row.cells.map((cell) => (
                                            <TableCell key={cell.id}>
                                                {cell.value?.content ?? cell.value}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </DataTable>
            <TablePagination
                currentPageSize={size}
                onPageChange={onPageChange}
                totalItems={count}
            />

        </Card>
    )



}
export default ProviderPage;