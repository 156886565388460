import {
    Button,
    Form,
    TextInput,
    Row,
    Column,
    ButtonSet,
    Dropdown,
    DataTable,
    OverflowMenu,
    OverflowMenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableToolbar,
    TableToolbarContent,Loading,
} from "carbon-components-react";
import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../http-common";
import { SaveModel24, Search32 } from "@carbon/icons-react";
import { AuthContext } from "../../contexts/AuthContext";
import { yupResolver } from "@hookform/resolvers/yup";
import Card from "../../components/Card/Card";
import TablePagination from "../../components/Pagination/Pagination";
import CurrencyInput from './CurrencyInput';

const tableHeaders = [
    {
        header: "NUIT",
        key: "documentCode",
    },
    {
        header: "Fornecedor",
        key: "firstName",
    },
    {
        header: "Valor a pagar",
        key: "payment",
    },

];


const PaymentForm = ({ mode, url, sucessMessage, onSucessMessage }) => {
    const [formMode] = useState(mode);
    const [payment, setPayment] = useState({
    });
    const [provinces, setProvinces] = useState([]);
    const [fairs, setFairs] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [sellers, setSellers] = useState([]);
    const [count, setCount] = useState(0);
    const [size, setSize] = useState(20);
    const [parameters, setParameters] = useState();
    const [districtData, setDistrictData] = useState([]);
    const [newSellers, setNewSellers] = useState([]);
    const [selectedSeller, setSelectedSeller] = useState();
    const [amoutPaid, setAmoutPaid] = useState(0);
    const [rowsPayments, setRowsPayments] = useState([]);
    const [list, setList] = useState([]);
    const [showLoading, setShowLoading] = useState(false);

    const addPayment = () => {
        setRowsPayments([...rowsPayments, [selectedSeller, amoutPaid]]);
    };
    const navigate = useNavigate();

    const authContext =
        useContext(AuthContext);
    const onSearch = async (data) => {
        try {
            setShowLoading(true);

            const response = await http.get(
                `/seller/fair/${data.fairId}/${data.provinceId}/${data.districtId}`
            );
            getSellerData(response);
            setParameters(data);
            setShowLoading(false);

        } catch (error) { }
    };

    const onPageChange = async ({ page, pageSize }) => {
        try {
            var pag = page - 1;
            const response = await http.get(
                `/seller/fair/${parameters.fairId}/${parameters.provinceId}/${parameters.districtId}?page=${pag}&size=${pageSize}`
            );
            getSellerData(response);

        } catch (error) { }
    };


    const getSellerData = (response) => {
        const data = response.data.content.map((seller) => ({
            id: seller.id,
            documentCode: seller.documentCode,
            firstName: seller.firstName,
            payment: (<CurrencyInput placeholder="0.00 MT" type="text" name={seller.id} value='' />)

        }));
        setSellers(data);
        const newList = data.map(s => {
            return { id: s.id, payment: 0 }
        });
        setList(newList);
        setCount(response.data.totalElements);
    };


    function handleChange(e) {
        const { name, value } = e.target;

        const newList = list.map((s) => {
            if (s.id === name) {
                const updatedSeller = {
                    ...s, amoutPaid: value
                }
                return updatedSeller;
            }

            return s;
        });

        setList(newList);
    }


    const onHandleSubmit = async () => {

        try {
            setShowLoading(true);

            var paymentsList = list.map(s => {
                const value = document.getElementsByName(s.id)[0].value;

                return { id: s.id, payment: value, seller: { id: s.id }, fair: { id: getValues("fairId") }, user: { username: authContext.user.username } };

            });

            const filteredPayments = paymentsList.filter(s => s.payment.length > 0);

            if (filteredPayments.length > 0) {

                const response = await http.post(
                    '/create/sellers/payments', filteredPayments
                );

                toast.success("Pagamento efectuado com sucesso");

            } else {
                toast.error("Preencha o(s) campo(s) antes de salvar");
            }
            setShowLoading(false);

        } catch (error) {
            toast.error(error.response?.data.message);
        }
    };

    const fetchProvinces = async () => {
        try {
            const response = await http.get('/provinces');
            let items = response.data.map(e => {
                return { id: e.id, text: e.description, children: e.children };
            })
            return { success: true, data: items };
        } catch (error) {
            return { success: false };
        }
    };

    const fetchFairs = async () => {
        try {
            const response = await http.get('/fairs');
            let items = response.data.map(e => {
                return { id: e.id, text: e.description, };
            })
            return { success: true, data: items };
        } catch (error) {
            return { success: false };
        }
    };

    useEffect(() => {
        (async () => {
            setShowLoading(true);

            let response = await fetchProvinces();
            let getFairs = await fetchFairs();
            if (response.success) {

                setProvinces(response.data);

            }
            if (getFairs.success) {

                setFairs(getFairs.data);

            }
            setShowLoading(false);

        })()
    }, []);


    const { register, handleSubmit, reset, setValue, getValues, watch, formState: { errors }, } = useForm({ mode: "onSubmit" });
    const params = useParams();
    function onChangeSeller(e) {
        payment.provinceId = e.selectedItem.id;
        setSelectedSeller(e.selectedItem.id);
    };
    function onChangeAmoutPaid(e) {

        payment.payment = e.target.value;
        setAmoutPaid(e.target.value
        );

    };
    function onProvinceChange(e) {
        payment.provinceId = e.selectedItem.id;
        setDistrictData(e.selectedItem.children);
        let response = e.selectedItem.children.map(c => {
            return { id: c.id, text: c.description };
        });
        setValue("provinceId", e.selectedItem.id);

        setDistricts(response);
    };

    function onDistrictChange(e) {
        payment.districtId = e.selectedItem.id;
        setValue("districtId", e.selectedItem.id);
    };

    function onFairChange(e) {
        payment.fairId = e.selectedItem.id;
        setValue("fairId", e.selectedItem.id);
    };



    return (

        <Card title="Pagamentos">
            <Form
                className="data-table-search-form"
                onSubmit={handleSubmit(onSearch)}
            >
                <Row>

                    <Column sm={3} md={4} lg={4}>
                        <Dropdown
                            id="provinceId"
                            titleText="Provincia"
                            label="Selecione"
                            items={provinces}
                            onChange={onProvinceChange}
                            name="provinceId"
                            itemToString={item => (item ? item.text : '')}
                        />

                        <p className="field-error">{errors.provinceId?.message}</p>
                    </Column>

                    <Column sm={3} md={4} lg={4}>
                        <Dropdown
                            id="districtId"
                            titleText="Distrito"
                            label="Selecione"
                            items={districts}
                            onChange={onDistrictChange}
                            name="districtId"
                            itemToString={item => (item ? item.text : '')}
                        />

                        <p className="field-error">{errors.districtId?.message}</p>
                    </Column>

                    <Column sm={3} md={4} lg={4}>
                        <Dropdown
                            id="fairId"
                            titleText="Feira"
                            label="Selecione"
                            items={fairs}
                            onChange={onFairChange}
                            name="fairId"
                            itemToString={item => (item ? item.text : '')}
                        />

                        <p className="field-error">{errors.fairId?.message}</p>
                    </Column>

                </Row>

                <Column>
                    <ButtonSet>
                        <Button
                            type="submit"
                            renderIcon={Search32}
                            iconDescription="Search"
                        >
                            Pesquisar
                        </Button>
                    </ButtonSet>
                </Column>

            </Form>
            {
                                        showLoading == true ?
                                            <Loading
                                                description="Active loading indicator" withOverlay={false}
                                                large /> : ""
                                    }
            <DataTable rows={sellers} headers={tableHeaders} isSortable useZebraStyles>
                {({ rows, headers, getHeaderProps, getTableProps }) => (
                    <TableContainer>
                        <TableToolbar aria-label="data table toolbar">
                            <TableToolbarContent>



                            </TableToolbarContent>
                        </TableToolbar>

                        <Table {...getTableProps()}>
                            <TableHead>

                                <TableRow>

                                    {headers.map((header) => (
                                        <TableHeader {...getHeaderProps({ header })}>
                                            {header.header}
                                        </TableHeader>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow key={row.id}>
                                        {row.cells.map((cell) => (

                                            <TableCell key={cell.id}>{cell.value}</TableCell>


                                        )

                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </DataTable>
            <TablePagination
                currentPageSize={size}
                onPageChange={onPageChange}
                totalItems={count}
            />

            <Row className="mt-2">
                <Column sm={12} md={12} lg={12} className="form-buttons">
                    <ButtonSet>
                        <Button
                            type="submit"
                            onClick={onHandleSubmit}
                            renderIcon={SaveModel24}
                            iconDescription="Save"
                        >
                            Salvar
                        </Button>
                    </ButtonSet>
                </Column>
            </Row>
        </Card>
    );
};

export default PaymentForm;