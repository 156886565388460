import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import http from "../http-common";

const useApplication = (uuid) => {
  const [application, setApplication] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wfDistricts, setWfDistricts] = useState([]);

  const getDistricts = async (provinceId) => {
    if (provinceId) {
      try {
        const response = await http.get(
          "/utils/provinces/" + provinceId + "/districts"
        );
        setDistricts(response.data);
      } catch (error) {
        toast.error(error?.response?.data.message);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await http.get(`/applications/uuid/${uuid}`);
        setApplication(result.data);
        getDistricts(result.data.provinceId);
      } catch (error) {
        toast.error(error.response?.data.message);
      }
    };

    fetchData();
  }, [uuid]);

  return { application, districts };
};

export default useApplication;
