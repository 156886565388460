import React, { useEffect,useState } from "react";
import Card from "../../components/Card/Card";
import ProductForm from "./ProductForm";


const UpdateProductPage = ()=>{

    return ( 
        <Card 
        >
            <ProductForm  
                mode={"UPDATE"}
                url={"/sellerproducts"}
                sucessMessage={"Produto atualizado com sucesso"}
                onSuccessNavigateTo={"../inicio"}
            />
        </Card>
    );
};

export default UpdateProductPage;


