import React from "react";
import Card from "../../components/Card/Card";


const InfoPage = () => {

    return (

        <div className="bx--row">
            <div className="bx--col-lg-6 content"></div>

            <div className="bx--col-sm-12 bx--col-lg-6">
                <Card title="Informação">
                    <p>
                        Aguarde Pelo estado de Aprovacao
                    </p>
                </Card>
            </div>
        </div>
    );
};

export default InfoPage;