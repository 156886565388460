import React from "react";
import { useParams } from "react-router-dom";
import Card from "../../components/Card/Card";
import UserForm from "./UserForm";

const UpdateUserPage = () => {

  const params = useParams();

  return (
    <Card title="Editar Utilizador">
      <UserForm
        mode={"UPDATE"}
        url={"/users/" + params.id}
        successMessage={"Conta actualizada com sucesso"}
        onSuccessNavigateTo={"../utilizadores"}
        userId={params.id}
      />
    </Card>
  );
};
export default UpdateUserPage;
