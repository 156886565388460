import React from "react";
import { useParams } from "react-router-dom";
import Card from "../../components/Card/Card";
import ProviderForm from "./ProviderForm";

const UpdateProviderPage = () => {

    const params = useParams();

    return (
        <Card title="Actualizar Dados do Fornecedor">
            <ProviderForm
                mode={"UPDATE"}
                url={"/sellers/" + params.id}
                successMessage={"Conta actualizada com sucesso"}
                onSuccessNavigateTo={"../fornecedores"}
                providerId={params.id}
            />

        </Card>
    )
}

export default UpdateProviderPage;