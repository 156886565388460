import React from "react";

const CreateContractPage = () => {
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Registar Contrato</h3>
      </div>
      <div className="card-body">
        <div className="bx--row">
          <div className="bx--col-lg-4">Total</div>
        </div>
      </div>
    </div>
  );
};

export default CreateContractPage;
