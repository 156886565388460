import axios from "axios";

const axiosInstance = axios.create({
  // baseURL: "http://testefeiraside.ologa.com:8989/api/v1",
  baseURL: "http://www.feiraside.ologa.com:9090/ide-feiras/api/v1",
    // baseURL: "http://localhost:8080/ide-feiras/api/v1",
    // baseURL: "http://192.168.88.2:8080/ide-feiras/api/v1",


  headers: {
    "Content-type": "multipart/form-data",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem("principal");
    if (user) {
      config.headers.Authorization = `Bearer ${JSON.parse(user).token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
