import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../components/Card/Card";
import { AuthContext } from "../../contexts/AuthContext";

const LandingPage = () => {
  const { user, isCompetitor} = useContext(AuthContext);


  const navigate = useNavigate();


  const handlNav = () => {
    navigate("../actualizar-candidatura/", {
      replace: true,
    });
  };

  useEffect(() => {
  }, []);

  return (
    <Card title={"Olá " + user.firstName + " " + user.lastName }>
      <div>
          
          <h2>Seja Bem Vindo</h2>
        </div>
    </Card>
  );
};

export default LandingPage;
