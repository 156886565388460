import { Column, Row } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Card from "../../components/Card/Card";
import http from "../../http-common";
import { PieChart, StackedBarChart } from "@carbon/charts-react";

const pieChartOptions = {
  title: "",
  resizable: true,
  legend: {
    alignment: "center",
  },
  pie: {
    alignment: "center",
  },
  height: "200px",
};


const pieChartOptions1 = {
  title: "",
  resizable: true,
  legend: {
    alignment: "center",
  },
  pie: {
    alignment: "center",
  },
  height: "400px",
};


const barChartOptions = {
  title: "",
  axes: {
    left: { mapsTo: "value" },
    bottom: { mapsTo: "group", scaleType: "labels" },
  },
  height: "400px",
};

const options = {
  title: "",
  axes: {
    left: {
      mapsTo: "value",
      stacked: true,
    },
    bottom: {
      mapsTo: "key",
      scaleType: "labels",
    },
  },
  height: "400px",
};
const DashboardPage = () => {
  const [stats, setStats] = useState({});
  const [byGender, setByGender] = useState([{
    group: 'A',
    key: 'A',

    value: 200,
  },{
    group: 'B',
    key: 'B',

    value: 120.0,
  },{
    group: 'C',
    key: 'C',

    value: 802,
  },{
    group: 'D',
    key: 'D',

    value: 520,
  },]);
  const [byExistingBusiness, setByExistingBusiness] = useState([{
    group: 'A',
    key: 'A',

    value: 200,
  },{
    group: 'B',
    key: 'B',

    value: 120.0,
  },{
    group: 'C',
    key: 'C',

    value: 802,
  },{
    group: 'D',
    key: 'D',

    value: 520,
  },]);
  const [byProvince, setByProvince] = useState([]);
  const [bySubmitedCandidature, setBySubmitedCandidature] = useState([{
    group: 'A',
    key: 'A',

    value: 200,
  },{
    group: 'B',
    key: 'B',

    value: 120.0,
  },{
    group: 'C',
    key: 'C',

    value: 802,
  },{
    group: 'D',
    key: 'D',

    value: 520,
  },]);
  const [byProvinceSubmitedCandidature, setByProvinceSubmitedCandidature] = useState([{
    group: 'A',
    key: 'A',

    value: 200,
  },{
    group: 'B',
    key: 'B',

    value: 120.0,
  },{
    group: 'C',
    key: 'C',

    value: 802,
  },{
    group: 'D',
    key: 'D',

    value: 520,
  },]);
  const [bChartDataVolumesEx, setbChartDataVolumesEx] = useState([{
    group: 'A',
    key: 'A',

    value: 200,
  },{
    group: 'B',
    key: 'B',

    value: 120.0,
  },{
    group: 'C',
    key: 'C',

    value: 802,
  },{
    group: 'D',
    key: 'D',

    value: 520,
  },]);

  const getStats = async () => {
    try {
      const response = await http.get("/applications/stats");
      setStats(response.data);
      setByGender(response.data.byGender);
      setByProvince(response.data.byProvince);
      setByExistingBusiness(response.data.byExistingBusiness);
      setBySubmitedCandidature(response.data.bySubmitedCandidature);
      setByProvinceSubmitedCandidature(response.data.byProvinceSubmitedCandidature);
    } catch (error) {
      toast.error("Erro ao carregar dados estatísticos");
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <>
      <h3>Dashboard</h3>
      <div className="dashboard">
        <Row>
          <Column xsm={12} sm={12} lg={4} xlg={4}>
            <Card title="Total de Clientes">
              <h1>{10}</h1>
            </Card>
          </Column>
          <Column xsm={12} sm={12} lg={4} xlg={4}>
            <Card title="Total por Género">
              <PieChart data={bChartDataVolumesEx} options={pieChartOptions}></PieChart>
            </Card>
          </Column>
          <Column xsm={12} sm={12} lg={4} xlg={4}>
            <Card title="Total: Plano vs Beneficios">
              <PieChart
                data={bChartDataVolumesEx}
                options={pieChartOptions}
              ></PieChart>
            </Card>
          </Column>
        </Row>
        <Row>
          <Column className="mt-2">
            <Card title="Total de Provedores por Província">
              <StackedBarChart
                data={bChartDataVolumesEx}
                options={options}
              ></StackedBarChart>
            </Card>
          </Column>
        </Row>
       <Row>
       <Column className="mt-2" >
            <Card title="Autorizacoes por Submeter e Submetidas">
              <PieChart
                data={bChartDataVolumesEx}
                options={pieChartOptions1}
              ></PieChart>
            </Card>
          </Column>
       </Row>
       <Row>
          <Column className="mt-2">
            <Card title="Autorizacoes por Submeter e Submetidas por Província">
              <StackedBarChart
                data={bChartDataVolumesEx}
                options={options}
              ></StackedBarChart>
            </Card>
          </Column>
        </Row>
      </div>
    </>
  );
};

export default DashboardPage;
