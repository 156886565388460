import { Select, SelectItem } from "carbon-components-react";

/**
 * A generic combo box for code description entities
 */

const GenericCombo = ({
  id,
  name,
  label,
  disabled,
  register,
  items,
  onChange,
}) => {
  return (
    <Select
      id={id}
      labelText={label}
      disabled={disabled}
      {...register(name)}
      onChange={onChange}
    >
      <SelectItem text="" value="" />
      {items.map((item) => (
        <SelectItem text={item.description} value={item.code} key={item.code} />
      ))}
    </Select>
  );
};

GenericCombo.defaultProps = {
  disabled: false,
};

export default GenericCombo;
