import React from "react";

const ApplicationFillingInstructions = () => {
  return (
    <div className="content">
      <p>
        <strong>
          Agora Emprega - Competição de Planos de Negócios (CPN) é implementada
          pela Secretaria de Estado da Juventude e Emprego (SEJE) através do
          Instituto Nacional da Juventude (INJ) como parte do Programa Emprega,
          com o apoio do Banco Mundial. O objectivo da CPN é facilitar o acesso
          ao financiamento para Moçambicanas e Moçambicanos com idades entre 18
          e 35 anos e impulsionar o crescimento económico inclusivo.
        </strong>
      </p>
      <h5>INSTRUÇÕES GERAIS DE PREENCHIMENTO</h5>
      <p>
        1. Todas as secções deste formulário devem ser preenchidas antes do
        envio. Se o/a candidato/a tem actualmente um negócio preencha as secções
        A, B, C e D. Se o/a candidato/a não tem actualmente um negócio preencha
        as secções A, B e D.
      </p>
      <p>
        2. Ao preencher a sua candidatura pelo “website”, depois de concluído,
        lembre-se sempre de salvar e proceder ao seu envio até ao dia
        30/04/2022.
      </p>
      <p>
        3. Ao preencher a sua candidatura em formato físico, escreva de forma
        legível e lembre-se de submeter até ao dia 30/04/2022 nos locais
        devidamente identificados na sua zona de residência ou domicílio do seu
        negócio.
      </p>
      <p>
        4. A equipa de implementação da CPN acusará o recebimento através do
        endereço de email ou telefone do/a candidato/a apresentado neste
        formulário. Após recepção da sua candidatura será enviado para si um
        <strong> Número Único de Referência</strong>. Por favor, conserve este
        número, pois será utilizado em todas as correspondências futuras.
      </p>
      <p>
        5. Ao longo do processo é possível que seja contactado por um
        representante da equipa da CPN para fornecer mais informações. Por favor
        reconfirme que o endereço de e-mail de contato do candidato principal e
        o número de telefone inseridos no formulário estão correctos.
      </p>
      <p>
        6. Se tiver alguma dúvida sobre a sua candidatura, não hesite em
        enviar-nos um e-mail para esclarecimentos através de
        <a href="mailto:agoraemprega@seje.gov.mz"> agoraemprega@seje.gov.mz</a>.
        Receberá uma resposta em 36 horas úteis. Alternativamente, pode ligar
        para a seguintes linhas de apoio:
        <strong> (+258) 85 730 5231 ou (+258) 87 730 5236</strong>.
      </p>
      <p>
        7. Todas{" "}
        <strong>
          {" "}
          as questões sinalizadas com *(asterísco) devem ser obrigatoriamente
          respondidas
        </strong>{" "}
        para a candidatura ser considerada válida.
        <strong>
          {" "}
          Antes de submeter, certifique se respondeu a todas elas
        </strong>
        .
      </p>
    </div>
  );
};

export default ApplicationFillingInstructions;
